import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './DocumentVerificationHome.scss';
import DocumentVerificationTable from "./DocumentVerificationPages/LandingPage/DocumentVerificationTable";
import { fileApiAxios, getAPI } from "../services/CepApiServices";
import FilterComponent from "../components/FilterComponent/FilterComponent";
import { getUserRole } from "../utils/token";
import { McIcon } from "@maersk-global/mds-react-wrapper";

const DocumentVerificationHome = () => {
    const history = useNavigate();
    const [tableData, setTableData] = useState<any>([]);
    const [mainData, setMainData] = useState<any>([]);
    const [isTableLoader, setIsTableLoader] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [dataObj, setDataObj] = useState<any>();
    const [userRole, setUserRole] = useState<any>("");

    const onLoadDataFetchDocumentLibrary = async () => {
          setIsTableLoader(true)
          const getDocumentLibraryList = await getAPI(
            `DocumentLibrary/GetDocumentsForReview`,
         
          );
          setTableData(getDocumentLibraryList);
          setMainData(getDocumentLibraryList);
          setIsTableLoader(false)
    };
    useEffect(() => {
        onLoadDataFetchDocumentLibrary();
    }, []);
    const onFilterClick = () => {
        setIsFilter(true);
    };
    const clearFilterFunc = () => {
        setDataObj({});
    };
    const filterDataFunc = (obj: any) => {
        const formData = {
            docStatus: obj?.docStatus ? obj?.docStatus : null,
            docCategory : obj?.docCategory ? obj?.docCategory.map((item) => item.label) : null,
            docNames : obj?.docNames ? obj?.docNames.map((item) => item.label) : null,
        };
        setDataObj(formData);
    };
    useEffect(() => {
        const getUserRoleAsync = async () => {
            const userRole = await getUserRole();
            setUserRole(userRole);
        };
        getUserRoleAsync();
    }, []);
    useEffect(() =>{
        if(dataObj){
          if(Object.keys(dataObj).length > 0){
            const newTableData = mainData?.filter(function (item: any) {
                if (
                    dataObj?.docStatus?.length > 0 &&
                    dataObj?.docCategory?.length > 0 &&
                    dataObj?.docNames?.length > 0
                ){
                    return (
                        dataObj?.docStatus?.indexOf(item?.statusId) !== -1 &&
                        dataObj?.docCategory?.indexOf(item?.documentCategoryName) !== -1 &&
                        dataObj?.docNames?.indexOf(item?.documentName) !== -1
                    );
                } else if (dataObj?.docStatus?.length > 0 && dataObj?.docCategory?.length > 0) {
                    return (
                        dataObj?.docStatus?.indexOf(item?.statusId) !== -1 &&
                        dataObj?.docCategory?.indexOf(item?.documentCategoryName) !== -1
                    );
                } else if (dataObj?.docStatus?.length > 0 && dataObj?.docNames?.length > 0) {
                    return (
                        dataObj?.docStatus?.indexOf(item?.statusId) !== -1 &&
                        dataObj?.docNames?.indexOf(item?.documentName) !== -1
                    );
                } else if (dataObj?.docCategory?.length > 0 && dataObj?.docNames?.length > 0) {
                    return (
                        dataObj?.docCategory?.indexOf(item?.documentCategoryName) !== -1 &&
                        dataObj?.docNames?.indexOf(item?.documentName) !== -1
                    );
                } else if (dataObj?.docStatus?.length > 0) {
                    return dataObj?.docStatus?.indexOf(item?.statusId) !== -1;
                } else if (dataObj?.docCategory?.length > 0) {
                    return dataObj?.docCategory?.indexOf(item?.documentCategoryName) !== -1;
                } else if (dataObj?.docNames?.length > 0) {
                    return dataObj?.docNames?.indexOf(item?.documentName) !== -1;
                } else {
                    return item;
                }
            });
            setTableData(newTableData);
          }
          else{
            onLoadDataFetchDocumentLibrary()
          }
        }
    }, [dataObj]);
    return (
        <div className="document-verificationMain">
            <div className="header-div">
                <div className="row">
                    <div className="col-md-3">
                    <h5>Document Verification</h5>
                    </div>
                    {(userRole == "CharteringManager" || userRole == "charteringManagerRepresentative") && (
                        <div className="col-md-2 filter-buttons">
                        <button className="clear-button" onClick={clearFilterFunc} data-testid="clear-filter">Clear</button>
                        <button
                        onClick={onFilterClick}
                        data-testid="apply-filter"
                        className="negotiation-filter-button"
                        >
                        <span>
                            <McIcon icon="sliders-horizontal"></McIcon>
                        </span>
                        <span>Filter</span>
                        </button>
                    </div>
                    )}
                </div>
            </div>
            <DocumentVerificationTable tableData={tableData} isTableLoader={isTableLoader} />
            <div className="negotiation-filter-section">
                {isFilter && (
                    <FilterComponent
                    filterFor={"DocumentVerification"}
                    onDataFunc={filterDataFunc}
                    closeFilterFunc={() => setIsFilter(false)}
                    clearFilter={clearFilterFunc}
                    />
                )}
            </div>
        </div>
    );
}

export default DocumentVerificationHome;