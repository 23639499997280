import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import "./SendIndicationModal.scss";
import {
  McButton,
  McIcon,
  McModal,
  McInput,
  McInputDate,
  McSelect,
} from "@maersk-global/mds-react-wrapper";
import {
  getAPI,
  fileApiAxios,
  downloadReportDocuments,
} from "../../../services/CepApiServices";
import VesselInput from "./VesselInput";
interface IPROPS {
  isSIRModal?: any;
  vessellist?: any;
  closeFunc?: any;
  detailsId?: any;
}
const SendIndicationModal = ({
  isSIRModal,
  vessellist,
  closeFunc,
  detailsId,
}: IPROPS) => {
  const [sendData, setSendData] = useState<any>([]);
  const [isInputModal, setisInputModal] = useState(false);
  const [isLoaderDiv, setIsLoaderDiv] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [vesselEmail, setVesselEmail] = useState<any>([]);
  const { register, handleSubmit, setValue, formState } = useForm();
  const { errors } = formState;
  const [stucturedArr, setStructeredArr] = useState<any>([]);
  const [fileTypes, setFileTypes] = useState<any>([]);
  const [selectedType, setSelectedType] = useState<any>([]);
  const [selectFileTypeMain, setSelectFileTypeMain] = useState<any>([]);
  const [fileSelected, setFileSelected] = useState<any>([]);
  const [clearfields, setClearFileds] = useState<any>([]);
  const [layconto, setlayconto] = useState({ layconto: "" });
  const [otherDocuments, setOtherDocuments] = useState<any>([]);
  const [checkeddoc, setCheckeddoc] = useState<any>([]);

  const getFormData = async (vessellistData: any) => {
    const imoList = vessellistData?.map((x) => x.imo).join(",");
    const dataGet = await getAPI(
      `IndicationRequest/GetVesselAssigneeDetail?imos=${imoList}`
    );
    if (dataGet) {
      setSendData(dataGet);
      const mainArrLen = dataGet?.vesselDetails?.length;
      setStructeredArr(
        Array.from({ length: mainArrLen }, () => ({ List: [] }))
      );
      setisInputModal(true);
      setIsLoaderDiv(false);
    }
  };
  const getFileType = async () => {
    await getAPI("IndicationRequest/GetAllDocumentTypes").then((res) => {
      if (res) {
        setSelectFileTypeMain(res);
        setFileTypes(res);
        setFileSelected([]);
      }
    });
  };
  useEffect(() => {
    if (vessellist?.length != 0 && isSIRModal) {
      setIsLoaderDiv(true);
      getFormData(vessellist);
      getFileType();
      getAllDocuments();
    }
  }, [isSIRModal]);

  const clickOnClose = (val: any) => {
    closeFunc(val);
    setisInputModal(false);
  };
  const handleArraystructer = (
    arrtype: any,
    data: any,
    arrstructer: any,
    indexVal: any
  ) => {
    const newArray = [
      {
        imo: arrtype.imo,
        vesselName: arrtype.vesselName,
        assigneeTypeId: 53,
        assigneeTypeName: "Vessel Owner",
        emailAddress:
          data[`ownerInput${indexVal}`] !== ""
            ? data[`ownerInput${indexVal}`]
            : arrtype.emailAddress,
        hasFullAccess: false,
        vesselRate: 0,
      },
    ];
    arrstructer.List.forEach((element, i) => {
      newArray.push({
        imo: arrtype.imo,
        vesselName: arrtype.vesselName,
        assigneeTypeId: 54,
        assigneeTypeName: "Representative",
        emailAddress:
          data[`representativeMail-${indexVal}${i}`] !== ""
            ? data[`representativeMail-${indexVal}${i}`]
            : element.email,
        hasFullAccess: false,
        vesselRate: 0,
      });
    });
    return newArray;
  };
  const vesselListFormFunc = (data: any) => {
    const createdArray = sendData?.vesselDetails?.map((element, i) => {
      element.assigneeTypeDetails = handleArraystructer(
        element.assigneeTypeDetails[0],
        data,
        vesselEmail[i],
        i
      );
      element.vesselRate = 0;
      return element;
    });
    return createdArray;
  };
  const onSubmit = async (data: any) => {
    setIsBtnDisabled(true);
    const formData = {
      id: 0,
      indicationRequestId: 0,
      userTypeId: 0,
      userType: "",
      deliveryPort: data.deliveryport,
      laycanFrom: data.laycanfrom === "" ? null : data.laycanfrom,
      laycanTo: data.layconto === "" ? null : data.layconto,
      period: data.period,
      timeCharterOptions: data.timecharterOption,
      hire: data.hire,
      redeliveryRange: data.redeliveryRange,
      addressCommission: data.addressCommission,
      additionalBrokerCommission: data.brokerCommission,
      charterParty: data.charterParty,
      charterBodApproval: data.charterApprovals,
      ownersBodApproval: data.ownersApproval,
      createdby: "",
      vesselDetails: vesselListFormFunc(data),
      indicationRequestDocuments: checkeddoc?.map((id) => ({ documentId: id })),
    };

    const resp = await fileApiAxios(
      `IndicationRequest/SaveIndicationRequestFromCharteringManager?charterRequestId=${detailsId}`,
      formData
    );
    if (resp?.status === 200) {
      clickOnClose("send");
      setIsBtnDisabled(false);
    } else {
      clickOnClose("failed");
      setIsBtnDisabled(false);
    }
  };

  const handleArrayList = (list: any, index: any) => {
    const mappedArr: any = stucturedArr?.map(function (x, i) {
      if (index === i) {
        x.List = list;
      }
      return x;
    });
    setVesselEmail([...mappedArr]);
  };
  const getAllDocuments = async () => {
    const documents = await getAPI(`DocumentLibrary/GetAllDocuments`);
    if (documents) {
      const filteredDocuments = documents.filter(
        (document) => document.documentCategoryId === 68
      );
      setOtherDocuments(filteredDocuments);
    }
  };
  const handleDocumentSelect = (documentId: any) => {
    setCheckeddoc((prevCheckedDocuments: any) => {
      if (prevCheckedDocuments.includes(documentId)) {
        return prevCheckedDocuments.filter((id) => id !== documentId);
      } else {
        return [...prevCheckedDocuments, documentId];
      }
    });
  };
  useEffect(() => {
    console.log(checkeddoc);
  }, [checkeddoc]);
  const downloadDocument = async (fileName: any, categoryName: any) => {
    await downloadReportDocuments(
      `DocumentLibrary/DownlaodDocument/${fileName}/${categoryName}`
    )
      .then((data) => data!.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        const a = document.createElement("a");
        const name = fileName;
        document.body.appendChild(a);
        a.href = url;
        a.download = `${name}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        console.log("Could not download the file");
      });
  };

  const minDateChangeHandler = (e) => {
    const newDate: any = moment(e.target.value).format("YYYY/MM/DD");
    setlayconto({ layconto: newDate });
    setValue("layconto", "");
  };
  
  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    inputValue = parseInt(inputValue, 10);
    if(!isNaN(inputValue)){
      event.target.value = Math.abs(inputValue);
    }
  }

  return (
    <>
      <form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
        <McModal
          width="auto"
          height="auto"
          open={isSIRModal}
          heading="Send Indication Request"
          backdropcloseactiondisabled
        >
          <div
            className="Indication-requestPage"
            data-testid="send-indication-modal"
          >
            {isLoaderDiv && (
              <div className="loader-div">
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-4">
                <div className="vessel-section">
                  <div className="headingDiv">
                    <span className="textOfHeaderDiv">
                      {"Owner/Representative Details"}
                    </span>
                  </div>
                  <div className="body-section">
                    {isInputModal &&
                      sendData?.vesselDetails?.map((val, i) => (
                        <VesselInput
                          register={register}
                          errors={errors}
                          vesselData={val}
                          indexVal={i}
                          setValue={setValue}
                          ismodal={isInputModal}
                          arrayList={handleArrayList}
                        />
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-section">
                  <div className="headingDiv">
                    <span className="textOfHeaderDiv">
                      {"Indication Request Details"}
                    </span>
                  </div>
                  <div className="form-fields">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="inputdiv">
                          <McInput
                            disabled
                            fit="small"
                            value="Maersk A/S"
                            label="Account"
                          ></McInput>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="inputdiv">
                          <McInput
                            {...register("deliveryport")}
                            label="Delivery Port"
                            fit="small"
                            autocomplete={"nope"}
                            data-testid="delivery-port"
                          ></McInput>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="inputdiv">
                              <McInputDate
                                data-testid="laycon-from-date"
                                {...register("laycanfrom")}
                                label="Laycan From"
                                fit="small"
                                oninput={minDateChangeHandler}
                              ></McInputDate>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="inputdiv">
                              <McInputDate
                                data-testid="laycon-to-date"
                                {...register("layconto")}
                                name="layconto"
                                label="Laycan To"
                                fit="small"
                                min={layconto.layconto}
                              ></McInputDate>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="inputdiv">
                          <McInput
                            {...register("period")}
                            label="Period"
                            fit="small"
                            autocomplete={"nope"}
                          ></McInput>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="inputdiv">
                          <McInput
                            {...register("timecharterOption")}
                            label="Time Charter Options"
                            fit="small"
                            autocomplete={"nope"}
                          ></McInput>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="inputdiv">
                          <div className="errorMargin">
                            <div className="hire-currency">
                              <div className="row">
                                <div className="col-md-3">
                                  <McSelect
                                    name="currency"
                                    label="Hire"
                                    fit="small"
                                    disabled={true}
                                    ariaPlaceholder="USD"
                                  ></McSelect>
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="number"
                                    className="hire-inputText"
                                    {...register("hire")}
                                    onChange={handleInputChange}
                                    placeholder="Enter"
                                    name="hire"
                                    min="0"
                                  ></input>
                                </div>
                                <div className="col-md-3">
                                  <span className="currency-text">
                                    /pdpr inclot
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="inputdiv">
                          <McInput
                            {...register("redeliveryRange")}
                            label="Re-Delivery Range"
                            fit="small"
                            autocomplete={"nope"}
                          ></McInput>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="inputdiv">
                          <McInput
                            {...register("addressCommission")}
                            label="Address Commission"
                            fit="small"
                            autocomplete={"nope"}
                          ></McInput>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="inputdiv">
                          <McInput
                            {...register("brokerCommission")}
                            label="Additional Broker's Commission"
                            fit="small"
                            autocomplete={"nope"}
                          ></McInput>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="inputdiv">
                          <McInput
                            {...register("charterParty")}
                            label="Charter Party"
                            fit="small"
                            autocomplete={"nope"}
                          ></McInput>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="inputdiv">
                          <McInput
                            {...register("ownersApproval")}
                            label="Owner's BOD Approval"
                            fit="small"
                            autocomplete={"nope"}
                          ></McInput>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="inputdiv">
                          <McInput
                            {...register("charterApprovals")}
                            label="Charterer's BOD Approval"
                            fit="small"
                            autocomplete={"nope"}
                          ></McInput>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="select-documents">
                          <span className="icon-text">
                            Select Other Documents
                          </span>
                          <div className="body-div">
                            <div className="row">
                              {otherDocuments?.map((data: any) => (
                                <div className="col-md-4">
                                  <div
                                    className={
                                      checkeddoc.includes(data?.documentId)
                                        ? "doc-select-div active-doc"
                                        : "doc-select-div"
                                    }
                                  >
                                    <span
                                      className="document-name"
                                      onClick={() =>
                                        handleDocumentSelect(data.documentId)
                                      }
                                      data-testid='dwld-icon'
                                    >
                                      {data?.documentName}
                                    </span>
                                    <span
                                      className="icon-div"
                                      onClick={() => {
                                        downloadDocument(
                                          data?.documentName,
                                          data?.documentCategoryName
                                        );
                                      }}
                                      data-testid='dwld-icon'
                                    >
                                      <McIcon icon="tray-arrow-down"></McIcon>
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <McButton
            data-testid="cancel"
            slot="secondaryAction"
            variant="secondary"
            dialogAction="cancel"
            click={() => clickOnClose("close")}
            role="cancel-cp"
          >
            Cancel
          </McButton>
          <McButton
            slot="primaryAction"
            variant="primary"
            type="submit"
            disabled={isBtnDisabled}
            data-testid="save-button"
          >
            Submit Indication Request
          </McButton>
        </McModal>
      </form>
    </>
  );
};
export default SendIndicationModal;
