import React, { useState, useEffect } from "react";

import {
  getAPI,
  downloadReportDocuments,
  fileApiAxios,
  deleteAPI,
} from "../../../services/CepApiServices";
import "./IndicationRequestDetails.scss";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import ConfirmationModal from "../../../CharterRequest/RequestPages/IndicationInvite/ConfirmationModal";
import UploadFiles from "./UploadFiles";
import { AppString } from "../../../utils/AppString";
import laderImage from "../../../assets/loader-image.png";
import { toast } from "react-toastify";
import { IconPdfDL } from "../../../components/Icons/pdfIcon";
import StepIndicatorFlow from "../../../components/StepIndicationFlow/StepIndicatorFlow";
import { getUserRole, getUserName } from "../../../utils/token";
const IndicationRequestDetails = () => {
  const [indicationRequestDetails, setIndicationRequestDetails] = useState<any>(
    []
  );
  const [indicationDocumentsDetails, setIndicationDocumentsDetails] =
    useState<any>([]);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [DocumentsData, setDocumentsData] = useState(false);
  const [uploadModalValue, setUploadModalValue] = useState(false);
  const [sourceTypeId, setSourceTypeId] = useState(0);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const location = useLocation();
  const checkAccpetdRequest = location?.state?.checkAccpetdRequest;
  const [showAcceptBtn, setShowAcceptBtn] = useState(false);
  const [fileUploaded, setFileUploaded] = useState<File>();
  const [isConfrmModal, setIsConfrmModal] = useState(false);
  const [progressStatus, setProgressStatus] = useState("");
  const [fileNameForDelete, setFileNameForDelete] = useState();
  const [fileIdForDelete, setFileIdForDelete] = useState(0);
  const [pageLoadOnDelete, setPageaLoadOnDelete] = useState(false);
  const [uploadDisable, setUploadDisable] = useState(false);
  const [rejectPopUp, setRejectPopUp] = useState(false);
  const [actionValue, setActionValue] = useState();
  const [txtValue, setTxtValue] = useState("");
  const [acceptVal, setAcceptValue] = useState(false);
  const [terminateVal, setTerminateValue] = useState(false);
  const [statusId, setStatusId] = React.useState();
  const [checkStatusValue, setCheckStatusValue] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [checkStatusValueDelete, setCheckStatusValueDelete] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [indexVo, setIndexVO] = React.useState(1);
  const [indexCm, setIndexCm] = React.useState(0);
  const [accessType, setAccessType] = useState(false);
  const [otherDocuments, setOtherDocuments] = useState<any>([]);
  const [availableDocumentList, setAvailableDocumentList] = useState<any>([]);
  const [selectedDocument, setSelectedDocument] = useState<any>({});
  const [getCurrentStage, setGetCurrentStage] = useState<any>("");
  const [toShowWarning, setToShowWarning] = useState(false);

  const fetchVesselListCRDetails = async (id: any) => {
    const userRole = await getUserRole();
    setIsTableLoader(true);
    const vessels = await getAPI(
      `IndicationRequest/GetIndicationInviteResponse/` +
        id +
        `?usertype=${userRole}`
    );
    if (vessels) {
      setShowAcceptBtn(vessels?.showAccept);
      setIndicationRequestDetails(vessels?.indicationRequestData);
      setIndicationDocumentsDetails(vessels?.indicationRequestDocuments);
      const accessTypeValue = vessels?.accessType === "Write" ? true : false;
      setAccessType(accessTypeValue);
      const StatusIdValue = vessels?.indicationRequestData?.[0]?.statusId;
      setStatusId(StatusIdValue);
      // const checkStatus = StatusIdValue == 5 || StatusIdValue == 16;
      //Removed 16 to disable accept/reject button as is terminated status
      const checkStatus = StatusIdValue == 5;
      // statusId = 6 || statusId >= 8 and StatusId <= 15 - to show upload and delete buttons
      // statusId < 6 || statusId = 7 || statusId = 16 || statusId = 17 - not to show upload and delete buttons
      if(StatusIdValue == 6 || (StatusIdValue >= 8 && StatusIdValue <= 15)){
        setCheckStatusValueDelete(true);
      }else if (StatusIdValue < 6 || StatusIdValue == 7 || StatusIdValue == 16 || StatusIdValue >= 17){
        setCheckStatusValueDelete(false);
      }
      setCheckStatusValue(checkStatus);
      let voRespond = vessels?.indicationRequestData?.some(function (
        item: any,
        index: any
      ) {
        if (item["userTypeId"] != 52 && item["userTypeId"] != 132) {
          setIndexVO(index);
        }
        return item["userTypeId"] != 52 && item["userTypeId"] != 132;
      });

      let voNotRespond = vessels?.indicationRequestData?.some(function (
        item: any,
        index: any
      ) {
        if (item["userTypeId"] == 52 || item["userTypeId"] == 132) {
          setIndexCm(index);
        }
        return (item["userTypeId"] == 52 || item["userTypeId"] == 132);
      });
      if (vessels?.indicationRequestDocuments?.length == 0) {
        setDocumentsData(true);
      } else {
        setDocumentsData(false);
      }
    }
    setIsTableLoader(false);
  };
  const deleteFilesHandler = async (id: any, fileName: any) => {
    setIsConfrmModal(true);
    setFileNameForDelete(fileName);
    setFileIdForDelete(id);
  };
 
  useEffect(() => {
    if(indicationRequestDetails?.length == 2 && showAcceptBtn == false && getCurrentStage == "Indication Received" && toastMessage !== "accepted"){
      setToShowWarning(true);
    }else{
      setToShowWarning(false);
    }
  }, [getCurrentStage, indicationRequestDetails, showAcceptBtn]);
  const handleDownload = async (id: any, fileName: string) => {
    let filesName = id + "_" + fileName;
    await downloadReportDocuments(
      `IndicationRequest/DownlaodIndicationRequestDocument/${filesName}`
    )
      .then((data) => data!.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        let a = document.createElement("a");
        let name = fileName;
        document.body.appendChild(a);
        a.href = url;
        a.download = `${name}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        console.log("data is not coming");
      });
  };

  const handleAcceptFun = async (actions: any) => {
    setRejectPopUp(true);
    if (actions == "reject") {
      setTxtValue(AppString.rejectModalTxt);
      setActionValue(actions);
      setToastMessage("rejected");
    } else if (actions == "accept") {
      setActionValue(actions);
      setTxtValue(AppString.acceptModalTxt);
      setToastMessage("accepted");
    } else if (actions == "terminate") {
      setActionValue(actions);
      setTxtValue(AppString.terminateModalTxt);
      setToastMessage("terminated");
    }
  };
  const onBtnClickOut = (id: any, fileName: any) => {
    return handleDownload(id, fileName);
  };
  const closingUpload = (act: any, data: any, typeValue: any) => {
    setUploadModalValue(false);
  };
  const SaveOrCancelReject = async (response: string) => {
    if (response == "Yes") {
      if (actionValue == "reject") {
        setUploadDisable(true);
      } else if (actionValue == "accept") {
        setAcceptValue(true);
      } else if (actionValue == "terminate") {
        setTerminateValue(true);
      }
      setRejectPopUp(false);
      let res = await getAPI(
        `IndicationRequest/UpdateIndicationRequestStatus/` +
          `?indicationId=${id}` +
          `&action=${actionValue}`
      );
      if (res) {
        toast.success(<div> Indication Request has {toastMessage}</div>, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
      } else {
        toast.error(<div> Indication Request has Failed</div>, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
      }
      setPageLoad(true);
    } else {
      //Only close the modal
      setRejectPopUp(false);
    }
  };
  const deleteFun = async (response: string) => {
    if (response == "Yes") {
      let res = await deleteAPI(
        `IndicationRequest/DeleteIndicationRequestDocument/` +
          fileIdForDelete +
          `?fileName=${fileNameForDelete}`
      );
      setIsConfrmModal(false);
      setPageaLoadOnDelete(true);
    } else {
      //Only close the modal
      setIsConfrmModal(false);
    }
  };
  const saveUploadedDocuments = async () => {
    const indicationRequestDocObj = [
      {
        id: 0,
        documentId: selectedDocument?.documentId,
        formFile: fileUploaded,
        indicationRequestId: id,
        documentName: fileUploaded?.name,
        createdBy: "",
        modifiedBy: "",
      },
    ];
    let formData: any = new FormData();
    let index = 0;
    for (let pair of indicationRequestDocObj) {
      formData.append(
        "indicationRequestDocuments[" + index + "].formFile",
        pair.formFile
      );
      formData.append("indicationRequestDocuments[" + index + "].id", pair.id);
      formData.append(
        "indicationRequestDocuments[" + index + "].documentId",
        pair.documentId
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].indicationRequestId",
        pair.indicationRequestId
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].documentName",
        pair.documentName
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].createdBy",
        pair.createdBy
      );
      formData.append(
        "indicationRequestDocuments[" + index + "].modifiedBy",
        pair.modifiedBy
      );
    }
    if (fileUploaded) {
      await fileApiAxios(
        `IndicationRequest/UploadFiles?indicationId=${id}`,
        formData
      ).then((result) => {
        if (result.data == true) {
          setProgressStatus("success");
          setPageaLoadOnDelete(true);
          setTimeout(function () {
            setUploadModalValue(false);
          }, 1500);
        } else {
          setProgressStatus("danger");
          setTimeout(function () {}, 1000);
        }
      });
    }
  };
  const receiveUploadedFile = (file: File, source: any) => {
    setFileUploaded(file);
    setSelectedDocument(source);
  };
  const getAvailableOtherDocuments = async () => {
    const documents = await getAPI(`DocumentLibrary/GetAllDocuments`);
    if (Array.isArray(documents)) {
      const filteredDocuments = documents?.filter(
        (document) => document.documentCategoryId === 68
      );
      const indicationDocumentIds = new Set(
        indicationDocumentsDetails?.map((doc) => doc.documentId)
      );
      const remainingDocuments = filteredDocuments?.filter(
        (document) => !indicationDocumentIds.has(document.documentId)
      );
      setAvailableDocumentList(remainingDocuments);
    }
  };
  useEffect(() => {
    getAvailableOtherDocuments();
  }, [indicationDocumentsDetails]);
  useEffect(() => {
    saveUploadedDocuments();
  }, [fileUploaded]);
  useEffect(() => {
    setTimeout(() => {
      fetchVesselListCRDetails(id);
      setPageaLoadOnDelete(false);
    }, 1500);
  }, [pageLoadOnDelete, pageLoad]);

  const checkEqualData = (data1: any, data2: any) => {
    if (data1 != data2 && data2 != "null") {
      return <span className="status-warning">{data2}</span>;
    } else if (data2 == "null") {
      return "";
    } else {
      return <span>{data2}</span>;
    }
  };
  const handleUploadPopUp = () => {
    setUploadModalValue(true);
  };
  return (
    <>
      <div className="indication-details-page">
        <div className="mainDiv">Indication Request Details</div>
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="indication-flow">
                <StepIndicatorFlow
                  type={"Indication"}
                  indicationID={id}
                  updateApiProps={isTableLoader}
                  getStatusFlow={setGetCurrentStage}
                />
              </div>
            </div>
          </div>
          <div className="indicationDetails-section">
          {toShowWarning && (
              <span className="warning-text">Already another Indication has been accepted for the same Charter Request</span>
         
            )}
            {indicationRequestDetails?.length == 2 &&
              checkStatusValue &&
              showAcceptBtn == true &&
              accessType && (
                <div className="indication-button-group">
                  <button
                    className="button-approve"
                    data-testid="accept-button"
                    onClick={() => {
                      handleAcceptFun("accept");
                    }}
                  >
                    Accept
                  </button>
                  <button
                    className="button-reject"
                    data-testid="reject-button"
                    onClick={() => {
                      handleAcceptFun("reject");
                    }}
                  >
                    Reject
                  </button>
                </div>
              )
            }
            {/* {(statusId == 6 && accessType) && <div className="indication-button-group" onClick={() => { handleAcceptFun("terminate") }}><button className="button-reject" data-testid="terminate-button">Terminate</button></div>} */}
            <ConfirmationModal
              onModalOpen={rejectPopUp}
              onModalClose={SaveOrCancelReject}
              modalTxt={txtValue}
              width={"auto"}
              height={"130px"}
            />

            <div className="headingDiv-indication">
              <div className="headingIcon">
                <McIcon
                  icon={"file"}
                  color={"black"}
                  style={{ verticalAlign: "center" }}
                ></McIcon>
              </div>
              <span
                className="textOfHeaderDiv"
                data-testid="chartering-manager"
              >
                {"Chartering Manager"}
              </span>
            </div>

            <div className="main-container ">
              <div className="vsl-details-overview">
                <div className="row vsl-details-data">
                  <div className="col">
                    <label className="cr-vsl-details-header">
                      Delivery Port
                    </label>
                    <div className="vsl-details-value font-bold">
                      {indicationRequestDetails?.[indexCm]?.deliveryPort}
                    </div>
                  </div>
                  <div className="col ">
                    <label className="cr-vsl-details-header">Laycan From</label>
                    <div className="vsl-details-value font-bold">
                      {indicationRequestDetails?.[indexCm]?.laycanFrom == null
                        ? ""
                        : moment(
                            indicationRequestDetails?.[indexCm]?.laycanFrom
                          ).format("DD-MMM-YYYY")}
                    </div>
                  </div>
                  <div className="col ">
                    <label className="cr-vsl-details-header">Laycan To</label>
                    <div className="vsl-details-value font-bold">
                      {indicationRequestDetails?.[indexCm]?.laycanTo == null
                        ? ""
                        : moment(
                            indicationRequestDetails?.[indexCm]?.laycanTo
                          ).format("DD-MMM-YYYY")}
                    </div>
                  </div>
                  <div className="col ">
                    <label className="cr-vsl-details-header">Period</label>
                    <div className="vsl-details-value font-bold">
                      {indicationRequestDetails?.[indexCm]?.period}
                    </div>
                  </div>
                  <div className="col">
                    <label className="cr-vsl-details-header">
                      Time Charter Options
                    </label>
                    <div className="vsl-details-value font-bold">
                      {indicationRequestDetails?.[indexCm]?.timeCharterOptions}
                    </div>
                  </div>
                  <div className="col">
                    <label className="cr-vsl-details-header">Hire</label>
                    <div className="vsl-details-value font-bold">
                      {indicationRequestDetails?.[indexCm]?.hire}
                    </div>
                  </div>
                </div>
                <div className="row vsl-details-data">
                  <div className="col">
                    <label className="cr-vsl-details-header">
                      Re-Delivery Range
                    </label>
                    <div className="vsl-details-value font-bold">
                      {indicationRequestDetails?.[indexCm]?.redeliveryRange}
                    </div>
                  </div>
                  <div className="col">
                    <label className="cr-vsl-details-header">
                      Address Commission
                    </label>
                    <div className="vsl-details-value font-bold">
                      {indicationRequestDetails?.[indexCm]?.addressCommission}
                    </div>
                  </div>
                  <div className="col ">
                    <label className="cr-vsl-details-header">
                      Additional Broker Commission
                    </label>
                    <div className="vsl-details-value font-bold">
                      {
                        indicationRequestDetails?.[indexCm]
                          ?.additionalBrokerCommission
                      }
                    </div>
                  </div>
                  <div className="col ">
                    <label className="cr-vsl-details-header">
                      Charter Party
                    </label>
                    <div className="vsl-details-value font-bold">
                      {indicationRequestDetails?.[indexCm]?.charterParty}
                    </div>
                  </div>
                  <div className="col ">
                    <label className="cr-vsl-details-header">
                      Owner's BOD Approval
                    </label>
                    <div className="vsl-details-value font-bold">
                      {indicationRequestDetails?.[indexCm]?.ownersBodApproval}
                    </div>
                  </div>
                  <div className="col ">
                    <label className="cr-vsl-details-header">
                      Charter's BOD Approval
                    </label>
                    <div className="vsl-details-value font-bold">
                      {indicationRequestDetails?.[indexCm]?.charterBodApproval}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {indicationRequestDetails?.length == 2 && (
            <div className="indicationDetails-section">
              <div className="tab-row tab-section" slot="panel">
                <div className="headingDiv-indication">
                  <div className="headingIcon">
                    <McIcon
                      icon={"file"}
                      color={"black"}
                      style={{ verticalAlign: "center" }}
                    ></McIcon>
                  </div>
                  <span className="textOfHeaderDiv" data-testid="vessel-owner">
                    {"Vessel Owner"}
                  </span>
                </div>
                <div className="main-container">
                  <div className="vsl-details-overview">
                    <div className="row vsl-details-data">
                      <div className="col">
                        <label className="cr-vsl-details-header">
                          Delivery Port
                        </label>
                        <div className="vsl-details-value font-bold">
                          {checkEqualData(
                            indicationRequestDetails?.[indexCm]?.deliveryPort,
                            indicationRequestDetails?.[indexVo]?.deliveryPort
                          )}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">
                          Laycan From
                        </label>
                        <div className="vsl-details-value font-bold">
                          {checkEqualData(
                            moment(
                              indicationRequestDetails?.[indexCm]?.laycanFrom
                            ).format("DD-MMM-YYYY"),
                            moment(
                              indicationRequestDetails?.[indexVo]?.laycanFrom
                            ).format("DD-MMM-YYYY")
                          )}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">
                          Laycan To
                        </label>
                        <div className="vsl-details-value font-bold">
                          {checkEqualData(
                            moment(
                              indicationRequestDetails?.[indexCm]?.laycanTo
                            ).format("DD-MMM-YYYY"),
                            moment(
                              indicationRequestDetails?.[indexVo]?.laycanTo
                            ).format("DD-MMM-YYYY")
                          )}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">Period</label>
                        <div className="vsl-details-value font-bold">
                          {checkEqualData(
                            indicationRequestDetails?.[indexCm]?.period,
                            indicationRequestDetails?.[indexVo]?.period
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <label className="cr-vsl-details-header">
                          Time Charter Options
                        </label>
                        <div className="vsl-details-value font-bold">
                          {checkEqualData(
                            indicationRequestDetails?.[indexCm]
                              ?.timeCharterOptions,
                            indicationRequestDetails?.[indexVo]
                              ?.timeCharterOptions
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <label className="cr-vsl-details-header">Hire</label>
                        <div className="vsl-details-value font-bold">
                          {checkEqualData(
                            indicationRequestDetails?.[indexCm]?.hire,
                            indicationRequestDetails?.[indexVo]?.hire
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row vsl-details-data">
                      <div className="col">
                        <label className="cr-vsl-details-header">
                          Re-Delivery Range
                        </label>
                        <div className="vsl-details-value font-bold">
                          {checkEqualData(
                            indicationRequestDetails?.[indexCm]?.redeliveryRange,
                            indicationRequestDetails?.[indexVo]?.redeliveryRange
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <label className="cr-vsl-details-header">
                          Address Commission
                        </label>
                        <div className="vsl-details-value font-bold">
                          {checkEqualData(
                            indicationRequestDetails?.[indexCm]
                              ?.addressCommission,
                            indicationRequestDetails?.[indexVo]?.addressCommission
                          )}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">
                          Additional Broker Commission
                        </label>
                        <div className="vsl-details-value font-bold">
                          {checkEqualData(
                            indicationRequestDetails?.[indexCm]
                              ?.additionalBrokerCommission,
                            indicationRequestDetails?.[indexVo]
                              ?.additionalBrokerCommission
                          )}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">
                          Charter Party
                        </label>
                        <div className="vsl-details-value font-bold">
                          {checkEqualData(
                            indicationRequestDetails?.[indexCm]?.charterParty,
                            indicationRequestDetails?.[indexVo]?.charterParty
                          )}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">
                          Owner's BOD Approval
                        </label>
                        <div className="vsl-details-value font-bold">
                          {checkEqualData(
                            indicationRequestDetails?.[indexCm]
                              ?.ownersBodApproval,
                            indicationRequestDetails?.[indexVo]?.ownersBodApproval
                          )}
                        </div>
                      </div>
                      <div className="col ">
                        <label className="cr-vsl-details-header">
                          Charter's BOD Approval
                        </label>
                        <div className="vsl-details-value font-bold">
                          {checkEqualData(
                            indicationRequestDetails?.[indexCm]
                              ?.charterBodApproval,
                            indicationRequestDetails?.[indexVo]
                              ?.charterBodApproval
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12">
              <div className="upload-section">
                <div className="headingDiv-indication uploadHeading">
                  {uploadDisable == false &&
                    checkStatusValueDelete &&
                    accessType && (
                      <div className="uploadButton">
                        <McButton
                          icon="tray-arrow-up"
                          data-testid="upload-document"
                          onClick={handleUploadPopUp}
                          disabled={availableDocumentList.length === 0}
                        >
                          Upload Document
                        </McButton>
                      </div>
                    )}
                  <span className="textOfHeaderDiv">
                    {"Uploaded Documents"}
                  </span>
                </div>
                {isTableLoader && (
                  <div className="loader-div  ">
                    <div className="width-div">
                      <div className="image-div shipLoader" id="pot">
                        <img src={laderImage} alt="" />
                      </div>
                    </div>
                  </div>
                )}
                {DocumentsData && (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="NoFilesSection" data-testid="no-files">
                        <span>No Files Found</span>
                      </div>
                    </div>
                  </div>
                )}

                {indicationDocumentsDetails?.length > 0 && (
                  <div className="bodySection" data-testid="doc-details">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          {indicationDocumentsDetails?.map((data: any) => (
                            <div className="col-md-3">
                              <div className="attach-div">
                                {uploadDisable == false &&
                                  checkStatusValueDelete &&
                                  accessType && (
                                    <div
                                      className="delete-icon"
                                      onClick={() => {
                                        deleteFilesHandler(
                                          data?.id,
                                          data?.uploadedFileName
                                        );
                                      }}
                                    >
                                      <McIcon
                                        icon="trash"
                                        color="#c00"
                                        data-testid="delete-button"
                                      ></McIcon>
                                    </div>
                                  )}
                                <div
                                  className="download-icon"
                                  onClick={() => {
                                    onBtnClickOut(
                                      data?.id,
                                      data?.uploadedFileName
                                    );
                                  }}
                                >
                                  <McIcon icon="tray-arrow-down"></McIcon>
                                </div>
                                <p
                                  className="file-name"
                                  data-testid="file-name"
                                >
                                  <IconPdfDL
                                    nameOfDoc={
                                      data?.documentName.split(".").slice(-1)[0]
                                    }
                                  ></IconPdfDL>{" "}
                                  {data?.documentName
                                    .split(".")
                                    .slice(0, -1)
                                    .join(".")}
                                </p>
                                <div
                                  className="uploaded-by"
                                  data-testid="uploaded-by"
                                >
                                  Uploaded By: {data?.uploadedBy}
                                </div>
                              </div>
                            </div>
                          ))}

                          <ConfirmationModal
                            onModalOpen={isConfrmModal}
                            onModalClose={deleteFun}
                            modalTxt={AppString.deleteModalTxt}
                            width={"400px"}
                            height={"130px"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {uploadDisable == false && accessType && (
              <UploadFiles
                onOpen={uploadModalValue}
                closeUpload={closingUpload}
                fileUpload={receiveUploadedFile}
                availableDocumentList={availableDocumentList}
                progressStatusRes={progressStatus}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IndicationRequestDetails;
