import {
  McModal,
  McButton,
  McCheckbox,
} from "@maersk-global/mds-react-wrapper";
import { useForm } from "react-hook-form";
import react, { useEffect, useState } from "react";
import { postAPI, getAPI } from "../../../../services/CepApiServices";
import { toast } from "react-toastify";
interface IPROPS {
  isModalOpen?: boolean;
  onAddModalClose?: any;
  indicationId?: any;
  reRender?: any;
}
const LumpsumModal = ({
  isModalOpen,
  onAddModalClose,
  indicationId,
  reRender,
}: IPROPS) => {
  const [existingData, setExistingData] = useState<any>([]);
  const [isExistingData, setIsExistingData] = useState<any>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      lumsumTitel: "",
      lumpsumzValue: "",
      lumpsumSuffix: "",
    },
  });
  const statusCall = (response: any) => {
    if (response.StatusCode !== 400) {
      reRender();
      onAddModalClose();
      toast.success(<div> Lumpsum Added successfully </div>, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    } else {
      onAddModalClose();
      toast.error(<div> Lumpsum not Added </div>, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    }
  };
  const onSubmit = async (data) => {
    if (isExistingData === false) {
      const payload = {
        detailId: 0,
        indicationId: indicationId,
        title: data.lumsumTitel,
        prefix: "USD",
        suffix: data.lumpsumSuffix,
        value: data.lumpsumzValue,
        isMapped: true,
      };
      const response = await postAPI(
        "InitialOffer/SaveLumpsumAdditionalLine",
        payload
      );
      statusCall(response);
    } else {
      let payload: any = [];
      const existData = existingData.filter(function (ele) {
        return ele.isChecked === true;
      });
      existData.forEach((item: any) => {
        payload.push(item.detailId);
      });
      const response = await postAPI(
        `InitialOffer/SaveExistingLumpsumAdditionalLineForIndication?indicationId=${indicationId}`,
        payload
      );
      statusCall(response);
    }
  };
  const getExixtingData = async () => {
    const response = await getAPI(
      `InitialOffer/GetLumpsumAdditionalLine/${indicationId}`
    );
    if (response) {
      if (response.length > 0) {
        const newResponse = response.map((item: any) => ({
          ...item,
          detailId: item.detailId,
          indicationId: item.indicationId,
          isMapped: item.isMapped,
          prefix: item.prefix,
          suffix: item.suffix,
          title: item.title,
          value: item.value,
          isChecked: false,
        }));
        setExistingData(newResponse);
      }
    }
  };
  const handleCheck = (detailId: any) => {
    const updatedData = existingData.map((item: any) => {
      if (item.detailId === detailId) {
        item.isChecked = !item.isChecked;
      }
      return item;
    });
    const isCheckedDisable = updatedData.some(function (ele) {
      return ele.isChecked === true;
    });
    if (isCheckedDisable) {
      setIsExistingData(true);
    } else {
      setIsExistingData(false);
    }

    setExistingData(updatedData);
  };
  useEffect(() => {
    if (isModalOpen) {
      getExixtingData();
    }
  }, [isModalOpen]);
  const onError = (error) => {};
  return (
    <form
      data-testid="form"
      className="charterMarketAddModal"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <McModal
        height="400px"
        width="800px"
        className="add-lumpsums-modal"
        heading={"Add Lumpsums"}
        open={isModalOpen}
        backdropcloseactiondisabled
      >
        {existingData.length > 0 && (
          <div className="existing-value">
            <div className="existing-value-title">
              Choose the Existing Lumpsum or Add new
            </div>
            <table className="existing-value-table">
              <tr>
                <th>Select</th>
                <th>Title</th>
                <th>Prefix</th>
                <th>Value</th>
                <th>Suffix</th>
              </tr>

              {existingData?.map((item: any) => (
                <>
                  <tr>
                    <td>
                      <McCheckbox
                        data-testid="input-checkbox"
                        checked={item.isMapped ? true : item.isChecked}
                        fit="small"
                        change={() => handleCheck(item?.detailId)}
                        label=""
                        disabled={item.isMapped}
                      ></McCheckbox>
                    </td>
                    <td>{item.title}</td>
                    <td>{item.prefix}</td>
                    <td>{item.value}</td>
                    <td>{item.suffix}</td>
                  </tr>
                </>
              ))}
            </table>
          </div>
        )}
        {existingData.length > 0 && (
          <div className="divider">
            <span></span>
            <span>OR</span>
            <span></span>
          </div>
        )}
        <div className="date-value">
          <div className="input-form">
            <label>Title</label>

            <input
              type="text"
              placeholder="Please enter title"
              {...register("lumsumTitel", { required: !isExistingData })}
              className="inputText"
              disabled={isExistingData}
              data-testid="lumpsum-title"
            />
            <div className="input-valueForm">
              <div className="input-dropdown">
                <input
                  type="text"
                  // disabled={isExistingData ? true : true}
                  disabled={true}
                  value={"USD"}
                  placeholder="Please enter Value"
                  className="inputText"
                  data-testid="lumpsum-prefix"
                />
              </div>
              <div className="input-value">
                <input
                  type="number"
                  step="any"
                  {...register("lumpsumzValue", { required: !isExistingData })}
                  placeholder="Please enter Value"
                  className="inputText"
                  disabled={isExistingData}
                  data-testid="lumpsum-value"
                />
              </div>
              <div className="input-suffix">
                <input
                  type="text"
                  {...register("lumpsumSuffix", { required: !isExistingData })}
                  placeholder="Please enter Unit"
                  className="inputText"
                  disabled={isExistingData}
                  data-testid="lumpsum-suffix"
                />
              </div>
            </div>
          </div>
        </div>

        <McButton
          slot="primaryAction"
          variant="primary"
          fit="small"
          type="submit"
          label="Add Lumpsums"
          data-testid="submit-button"
        ></McButton>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          dialogAction="cancel"
          role="cancel-button"
          fit="small"
          click={() => onAddModalClose()}
        >
          Cancel
        </McButton>
      </McModal>
    </form>
  );
};
export default LumpsumModal;
