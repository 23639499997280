import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./DetailsTab.scss";
import { McStepIndicator } from "@maersk-global/mds-react-wrapper/components-core";
import { McTab, McTabBar, McButton } from "@maersk-global/mds-react-wrapper";
import CharterRequest from "../../CharterRequest";
import VesselRequirements from "../VesselRequirements/VesselRequirements";
import { toast } from "react-toastify";
//Component
import VesselsList from "../VesselsList/VesselsList";
import DetailsFilter from "../DetailsFilter/DetailsFilter";
import { McIcon } from "@maersk-global/mds-react-wrapper/components-core";
import { McInput } from "@maersk-global/mds-react-wrapper";
import { getAPI, postAPI, deleteAPI } from "../../../services/CepApiServices";
import VesselCompare from "../VesselCompare/VesselCompare";
import SendIndicationModal from "../SendIndicationModal/SendIndicationModal";
import DirectIndication from "../SendIndicationModal/SendDirectIndication";
import AssignRepresentativeModal from "../AssignRepresentativeModal/AssignRepresentativeModal";
import EditRepresentativeModal from "../EditRepresentativeModal/EditRepresentativeModal";
import RemoveRepresentativeModal from "../RemoveRepresentativeModal/RemoveRepresentativeModal";
import RequestWriteAccessModal from "../RequestWriteAccessModal/RequestWriteAccessModal";
import { getUserRole, getUserName } from "../../../utils/token";

const DetailsTab = () => {
  const search = window.location.search;
  const history = useNavigate();

  const params = new URLSearchParams(search);
  const id = params.get("id");

  const [filters, setFilters] = useState([]);
  const [isCmpBtn, setIsCmpBtn] = useState(true);
  const [isAssignRepresentativeBtn, setIsAssignRepresentativeBtn] = useState(true);
  const [disableAssignRepBtn, setDisableAssignRepBtn] = useState(true);
  const [isAssignRepresentativeModal, setIsAssignRepresentativeModal] = useState(false);
  const [isEditRepresentativeBtn, setIsEditRepresentativeBtn] = useState(false);
  const [isEditRepresentativeModal, setIsEditRepresentativeModal] = useState(false);
  const [isDeleteRepresentativeModal, setIsDeleteRepresentativeModal] = useState(false);
  const [isRequestWriteAccessModal, setIsRequestWriteAccessModal] = useState(false);
  const [requestWriteAccessSuccess, setRequestWriteAccessSuccess] = useState(false);
  const [isSendIndicationBtn, setIsSendIndicationBtn] = useState(true);
  const [isSendIndicationBtnCMR, setIsSendIndicationBtnCMR] = useState(true);
  const [isSIRModal, setIsSIRModal] = useState(false);
  const [isDirectModal, setIsDirectModal] = useState(false);
  const [isCompareModal, setIsCompareModal] = useState(false);
  const [isFilterPanel, setIsFilterPanel] = useState(false);
  const [crDetailsAPI, setCRDetailsAPI] = useState(1);
  const [submitType, setSubmitType] = useState("submitIndication");
  const [imoList, setImoList] = useState([]);
  const [CMREditDetails, setCMREditDetails] = useState([]);
  const [assignmentId, setAssignmentId] = useState(0);
  const [isCheckedTable, setIsCheckedTable] = useState(false);
  const [imoListCompare, setImoListCompare] = useState([]);
  const [isDirect, setIsDirect] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [searchVslNameText, setVslNameText] = useState<string>("");
  const [maintainStatus, setMaintainStatus] = useState("");
  const [userName, setUserName] = useState<any>("");
  const [vslWithCMR, setVslWithCMR] = useState([]);
  const [allVessels, setAllVessels] = useState([]);
  const [deleteRepId, setDeleteRepId] = useState(0);
  const [userRole, setUserRole] = useState<any>("");
  const [isReadOnlyUser, setIsReadOnlyUser] = useState(false);
  const handleUpdateStatus = (status: any) => {
    setMaintainStatus(status);
  };
  const onFilterClose = (close: string) => {
    setIsFilterPanel(false);
    if (close == "save") {
      setCRDetailsAPI(crDetailsAPI + 1);
      setIsFilterPanel(false);
    }
  };
  const onCRDetailsFilterClick = () => {
    setIsFilterPanel(!isFilterPanel);
  };

  const fetchFiltersForCRDetails = async (crNo: any) => {
    const filtersData = await getAPI(
      `CharterRequest/GetCharterRequestDetailFilter/` + crNo
    );
    setFilters(filtersData);
  };

  useEffect(() => {
    fetchFiltersForCRDetails(id);
  }, [isFilterPanel]);
  useEffect(() => {
    const getUserNameAsync = async () => {
      const UID = await getUserName();
      setUserName(UID);
    };
    getUserNameAsync();
  }, []);
  const compareVessel = () => {
    setIsCompareModal(true);
  };
  const sendIndicationModal = () => {
    setIsSIRModal(true);
    setSubmitType("submitIndication");
  };
  const checkReadOnlyRoles = (details)=> {
    const readOnlyEmails = details
      .filter((user) => user?.accessType === "Read")
      .map((user) => user?.email);
    const checkUserEMail = readOnlyEmails.includes(userName.email);
    setIsReadOnlyUser(checkUserEMail);
  }
  const fetchBrokerDetails = async (data) => {
    const crRqID = imoList[0]['imo'];
    try {
      let response: any;
      response = await getAPI(
        `IndicationRequest/GetCMRAssignment?CharterRequestId=` +
        id +
        `&VesselIMO=` +
        crRqID
      );
      setCMREditDetails(response);
      setAssignmentId(response?.assignmentId);
      checkReadOnlyRoles(response?.cmrAssignmentUserDetails);
    } catch (error) {
      console.log("error", error);
    }
  };
  const assignRepresentativesModal = () => {
    setIsAssignRepresentativeModal(true);
  }
  const editRepresentativesModal = () => {
    fetchBrokerDetails(imoList);
    setTimeout(() => {
      setIsEditRepresentativeModal(true);
    }, 1500);
  }
  const sendDirectIndication = () => {
    if (isDirect) {
      toast.error(
        <div>
          Initiate Negotiation cannot be proceed for Accepted/Negototiation
          stage Charter request
        </div>,
        {
          theme: "colored",
          icon: false,
          autoClose: 5000,
        }
      );
    } else {
      setIsDirectModal(true);
    }
  };
  const directNegotiationFunc = (status) => {
    setIsDirect(status);
  };
  const closeDirectIndicationModal = () => {
    setIsDirectModal(false);
  };
  const filterAppliedFunc = (applied: any) => {
    setIsFilterApplied(applied);
  };
  const closeSendIndication = (val: any) => {
    if (val == "send") {
      setCRDetailsAPI(crDetailsAPI + 1);
      setIsCheckedTable(true);
      setIsSendIndicationBtn(true);
      setIsSendIndicationBtnCMR(true);
      setIsCmpBtn(true);
      setImoList([]);
      toast.success(<div> Indication Request Sent</div>, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    } else if (val === "failed") {
      setCRDetailsAPI(crDetailsAPI + 1);
      setIsCheckedTable(true);
      setIsSendIndicationBtn(true);
      setIsSendIndicationBtnCMR(true);
      setIsCmpBtn(true);
      setImoList([]);
      toast.error(<div> Indication Request Failed</div>, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
    }
    setIsSIRModal(false);
  };
  const closeCompareModal = (type: any, data: any) => {
    setIsCompareModal(false);
    if (type === "submitCompare") {
      setIsSIRModal(true);
      setSubmitType("submitCompare");
      setImoListCompare(data);
    }
  };
  const handleSendIndicationBtn = (row: any) => {
    let result: any = [];
    // Filter out same imo rows
    allVessels?.filter((item: any, index) => {
      if (item?.assignedTo !== null) {
        result.push(item.imo);
      }
      return result;
    });
    if (row?.find((e) => result.includes(e.imo))) { //e.workflowStageId === 2 && 
      setIsSendIndicationBtn(true);
      setIsSendIndicationBtnCMR(true);
    } else {
      setIsSendIndicationBtn(false);
      setIsSendIndicationBtnCMR(false);
    }
    row?.length === 0 && setIsSendIndicationBtn(true);
    row?.length === 0 && setIsSendIndicationBtnCMR(true);
  };

  const handleAssignRepresentativesBtn = (row: any) => {
    let result: any = [];
    // Filter out same imo rows
    allVessels?.filter((item: any, index) => {
      if (item?.assignedTo !== null) {
        result.push(item.imo);
      }
      return result;
    });
    if (row?.find((e) => e.assignedTo == null && !result.includes(e.imo))) {
      if (row?.length === 1) {
        setIsAssignRepresentativeBtn(true);
        setIsEditRepresentativeBtn(false);
        setDisableAssignRepBtn(false);
      } else {
        setDisableAssignRepBtn(true);
      }
    } else if (row?.find((e) => e.assignedTo !== null)) {
      if (row?.length === 1) {
        setIsAssignRepresentativeBtn(false);
        setIsEditRepresentativeBtn(true);
        setIsSendIndicationBtn(true);
        setIsSendIndicationBtnCMR(false);
      } else {
        setIsEditRepresentativeBtn(false);
      }
    }
    row?.length === 0 && setIsAssignRepresentativeBtn(true);
    row?.length === 0 && setIsEditRepresentativeBtn(false);
  };

  const checkIndicationAccepted = () => {
    const acceptedRow = allVessels.filter((e: any) => e?.workflowStageId == 6);
    if (acceptedRow.length >= 1) {
      return false;
    } else {
      return true;
    }
  }

  const callCompareImoFunc = (selectedRows: any) => {
    setIsCheckedTable(false);
    setImoList(selectedRows);
    if (checkIndicationAccepted()) {
      handleSendIndicationBtn(selectedRows);
      selectedRows?.length !== 0 ? handleAssignRepresentativesBtn(selectedRows) : setDisableAssignRepBtn(true);
      handleAssignRepresentativesBtn(selectedRows);
    }
    if (selectedRows.length <= 10 && selectedRows.length >= 2) {
      setIsCmpBtn(false);
    } else {
      setIsCmpBtn(true);
      if (selectedRows.length > 10) {
        toast.error(<div> Please select only 10 vessel to compare</div>, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
      }
    }
  };
  const keyDown = (e: any) => {
    if (e.key === "Enter") {
      setVslNameText(e.target.value);
    }
  };
  const onSearchVslNameCancel = () => {
    setVslNameText("");
  };
  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };

  const assignCMRtoVsl = async (obj: any): Promise<void> => {
    const payload = obj;
    const status = await postAPI(`IndicationRequest/SaveCMRAssignment`, payload);
    if (status.StatusCode == 400 || status.message) {
      setTimeout(() => {
        notify("Failed to add representative", false)
      }, 1000);
      setIsAssignRepresentativeModal(false);
    }
    else {
      setTimeout(() => {
        notify("Representatives assigned successfully.", true)
      }, 1000);
      setIsAssignRepresentativeBtn(false);
      setIsSendIndicationBtn(true);
      setIsSendIndicationBtnCMR(true);
      setIsEditRepresentativeBtn(false);
      setIsAssignRepresentativeModal(false);
      setCRDetailsAPI(crDetailsAPI + 1);
      setIsCheckedTable(true);
    }
  };

  const addRepresentatives = (formatteddata: any) => {
    assignCMRtoVsl(formatteddata);
  }
  const editCMRVslDetails = async (obj: any): Promise<void> => {
    const payload = obj;
    const status = await postAPI(`IndicationRequest/UpdateCMRAssignment`, payload);
    if (status.StatusCode == 400 || status.message) {
      setTimeout(() => {
        notify("Failed to edit representative", false)
      }, 1000);
      setIsAssignRepresentativeModal(false);
    }
    else {
      setTimeout(() => {
        notify("Representatives edited successfully.", true)
      }, 1000);
      setIsEditRepresentativeBtn(false);
      setIsEditRepresentativeModal(false);
      setCRDetailsAPI(crDetailsAPI + 1);
      setIsCheckedTable(true);
    }
  };

  const setEditedCMRDetails = (formatteddata: any) => {
    editCMRVslDetails(formatteddata);
  };

  const deletedRepresentative = async (id: any): Promise<void> => {
    const status = await deleteAPI(`IndicationRequest/DeleteCMRAssignment?Id=` + id);
    if (status?.StatusCode == 400 || status?.message) {
      setTimeout(() => {
        notify("Failed to delete representative", false)
      }, 1000);
      setIsDeleteRepresentativeModal(false);
      setIsEditRepresentativeModal(false);
    }
    else {
      setTimeout(() => {
        notify("Representatives deleted successfully.", true)
      }, 1000);
      setIsEditRepresentativeBtn(false);
      setIsDeleteRepresentativeModal(false);
      setCRDetailsAPI(crDetailsAPI + 1);
      setIsCheckedTable(true);
      fetchBrokerDetails(imoList);
      setTimeout(() => {
        setIsEditRepresentativeModal(true);
      }, 1500);
    }
  };

  const setDeletedRepresentativeData = (repData: any) => {
    setDeleteRepId(repData);
    setIsEditRepresentativeModal(false);
    setIsDeleteRepresentativeModal(true);
  }

  const onSubmitDelete = (id: any) => {
    deletedRepresentative(id);
  }

  const setAvailableVsl = (data: any) => {
    setAllVessels(data);
  }
  const getRequestWriteAccess = async()=> {
    const response = await getAPI(
      `IndicationRequest/RequestCMRWriteAccess?CMRAssignmentId=` + assignmentId
    );
    if(response) {
      setRequestWriteAccessSuccess(true);
    }
  }

  const requestWriteAccess = (val:any)=> {
    setIsRequestWriteAccessModal(val);
  }

  const closeRequestWriteAccessModal = (type:any)=> {
    if(type=="submit") {
      getRequestWriteAccess();
    }
    setIsRequestWriteAccessModal(false);
    setIsEditRepresentativeModal(true);
  }

  useEffect(() => {
    const getUserRoleAsync = async () => {
      const userRole = await getUserRole();
      setUserRole(userRole);
    };
    getUserRoleAsync();
  }, []);

  return (
    <div className="charter-requestMain">
      <div>Charter Request Details</div>
      <div className="button-group">
        {userRole == "CharteringManager" && (
          <div className="buttons">
            <McButton
              data-testid="sendIndication"
              label="Send Indication"
              disabled={isSendIndicationBtn}
              onclick={() => {
                sendIndicationModal();
              }}
              fit="small"
            ></McButton>
          </div>
          )}
        {userRole == "charteringManagerRepresentative" && (<div className="buttons">
          <McButton
            data-testid="sendIndication"
            label="Send Indication"
            disabled={isSendIndicationBtnCMR}
            onclick={() => {
              sendIndicationModal();
            }}
            fit="small"
          ></McButton>
        </div>
        )}
        {userRole == "CharteringManager" && isCmpBtn && !isSendIndicationBtn && (
          <div className="buttons">
            <McButton
              data-testid="sendIndication"
              label="Initiate Investigation"
              disabled={isSendIndicationBtn}
              onclick={() => {
                sendDirectIndication();
              }}
              fit="small"
            ></McButton>
          </div>
        )}

        {userRole == "charteringManagerRepresentative" && (
          <div className="buttons">
            <McButton
              data-testid="sendIndication"
              label="Initiate Investigation"
              disabled={isSendIndicationBtnCMR}
              onclick={() => {
                sendDirectIndication();
              }}
              fit="small"
            ></McButton>
          </div>
        )}

        {/*Commenting Compare functionality, don't delete.*/}
        {/* <div className="buttons">
          <McButton
            data-testid="compare"
            label="Compare"
            disabled={isCmpBtn}
            onClick={() => compareVessel()}
            fit="small"
            variant="secondary"
          ></McButton>
        </div> */}
        {isAssignRepresentativeBtn && <div className="buttons">
          <McButton
            data-testid="assign-representatives"
            label="Assign representatives"
            disabled={disableAssignRepBtn}
            onClick={() => assignRepresentativesModal()}
            fit="small"
            variant="secondary"
          ></McButton>
        </div>
        }
        {isEditRepresentativeBtn && <div className="buttons">
          <McButton
            data-testid="edit-representatives"
            label="Edit representatives"
            onClick={() => editRepresentativesModal()}
            fit="small"
            variant="secondary"
          ></McButton>
        </div>
        }
      </div>
      {isSIRModal && (
        <SendIndicationModal
          detailsId={id}
          isSIRModal={isSIRModal}
          vessellist={
            submitType == "submitIndication" ? imoList : imoListCompare
          }
          closeFunc={closeSendIndication}
        ></SendIndicationModal>
      )}
      {isCompareModal && (
        <VesselCompare
          isCompareModal={isCompareModal}
          closeFunc={closeCompareModal}
          compareDataValue={imoList}
        ></VesselCompare>
      )}
      {isDirectModal && (
        <DirectIndication
          onLoadData={imoList}
          isSIRModal={isDirectModal}
          charterId={id}
          closeFunc={closeDirectIndicationModal}
          extendContract={false}
        />
      )}
      {isAssignRepresentativeModal && (<AssignRepresentativeModal
        isModalOpen={isAssignRepresentativeModal}
        infoData={addRepresentatives}
        onLoadData={imoList}
        crId={id}
        isCloseModal={() => setIsAssignRepresentativeModal(false)}
        isFromIndicationReq={false}
      ></AssignRepresentativeModal>
      )}
      {isEditRepresentativeModal && (<EditRepresentativeModal
        isModalOpen={isEditRepresentativeModal}
        infoData={CMREditDetails}
        isCloseModal={() => setIsEditRepresentativeModal(false)}
        setEditedCMRDetails={setEditedCMRDetails}
        deletedRepresentative={setDeletedRepresentativeData}
        crId={id}
        requestWriteAccessModal={requestWriteAccess}
        requestWriteAccessSuccess={requestWriteAccessSuccess}
        userRole={userRole}
        isReadOnlyUser={isReadOnlyUser}
        isExtended={false}
      ></EditRepresentativeModal>
      )}
      {isDeleteRepresentativeModal && (<RemoveRepresentativeModal
        onDeleteOpen={isDeleteRepresentativeModal}
        deleteRepId={deleteRepId}
        onSubmit={onSubmitDelete}
      >
      </RemoveRepresentativeModal>)}
      { isRequestWriteAccessModal && (<RequestWriteAccessModal 
      onModalOpen={isRequestWriteAccessModal} 
      onModalClose={closeRequestWriteAccessModal} 
      />)}
      {userRole !== "charteringManagerRepresentative" && (
      <div className="cr-rectangle">
        <McTabBar currentindex={0}>
          <McTab
            className="custom-height"
            slot="tab"
            label="Vessel Requirements"
          ></McTab>
          <div className="tab-row tab-section" slot="panel">
            <VesselRequirements crId={id} />
          </div>
        </McTabBar>
      </div>
      )}

      <div className="table-label">
        Select vessels to compare
        <div className="sub-section-cr">
          <div className="col-md-4">
            <div className="global-search-cr">
              <McInput
                className="search-box"
                icon="magnifying-glass"
                iconposition="left"
                label=""
                ariaPlaceholder="Search Vessel Name"
                fit="medium"
                onChange={(e: any) => setVslNameText(e.target.value)}
                value={searchVslNameText}
                onKeyDown={keyDown}
              ></McInput>
              <div
                className="closeIcon"
                onClick={() => onSearchVslNameCancel()}
                data-testid="close-icon"
              >
                <McIcon icon="times"></McIcon>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <button
              onClick={onCRDetailsFilterClick}
              data-testid="filter-button"
              className={`filter-button ${maintainStatus === "Completed" ? "no-filter" : ""
                }`}
            >
              <span>
                <McIcon icon="sliders-horizontal"></McIcon>
              </span>
              {isFilterApplied && <span className="heighlight"></span>}
              <span>Filter</span>
            </button>
          </div>
          {isFilterPanel && (
            <DetailsFilter
              closeFilterFunc={onFilterClose}
              filterDataCalled={filters}
              crId={id}
            />
          )}
        </div>
      </div>
      <div style={{ paddingTop: "15px" }}>
        {/* <div className="checkbox-table"> */}

        <VesselsList
          compareImoFunc={callCompareImoFunc}
          loadData={crDetailsAPI}
          crId={id}
          filterApplied={filterAppliedFunc}
          clearChecked={isCheckedTable}
          isDirectNegotiation={directNegotiationFunc}
          resetCheckBox={() => {
            setIsCheckedTable(false);
          }}
          searchVesselNameText={searchVslNameText}
          handleUpdateStatus={handleUpdateStatus}
          setAvailableVsl={setAvailableVsl}
        />

        {/* </div> */}
      </div>
    </div>
  );
};
export default DetailsTab;
