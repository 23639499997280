import React, { useState, useEffect } from "react";
import { McIcon, McCheckbox, McRadio } from "@maersk-global/mds-react-wrapper";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import moment from "moment";
import StandardClauseHistory from "../../NegotiationOfferPages/StandardOffer/standClauseHistrory";
interface IPROPS {
  subTermData?: any;
  indicationId?: any;
  handleChangeFunc?: any;
  submitData?: any;
  renderFunc?: any;
  handleChangeRadio?: any;
  isPrefilledCondition?: any;
}
const InputSection = ({
  subTermData,
  indicationId,
  handleChangeFunc,
  submitData,
  handleChangeRadio,
  renderFunc,
  isPrefilledCondition,
}: IPROPS) => {
  const [isedit, setIsEdit] = useState<any>(true);
  const [mainData, setmainData] = useState<any>();
  const handleSelectOnchange = (value: any, itemValue: any) => {
    handleChangeFunc(value.label, itemValue);
  };

  const differData = (
    itemData: any,
    isNegotiable: any,
    isPrefilledCondition: any
  ) => {
    if (
      itemData.dataType === "Dropdown" ||
      itemData.dataType === "Dropdown&Input"
    ) {
      return (
        <span className="differData">
          {itemData.value !== "No" ? "Yes" : "No"}
        </span>
      );
    }
    const joineddata: any = [];
    if (itemData?.diffs?.length > 0) {
      const newData: any = [];
      const oldData: any = [];
      itemData?.diffs?.forEach((element) => {
        if (element.operation === 2) {
          if (itemData.dataType === "Datetime") {
            const newText = element.text.replace(/T/g, " ");
            newData.push(newText);
            oldData.push(newText);
          } else if (itemData.dataType === "Date") {
            newData.push(element.text);
            oldData.push(element.text);
          } else {
            joineddata.push(element.text);
          }
        } else if (element.operation === 1) {
          if (itemData.dataType === "Datetime") {
            const newText = <b>{element?.text.replace(/T/g, " ")}</b>;
            newData.push(newText);
          } else if (itemData.dataType === "Date") {
            newData.push(<b>{element?.text}</b>);
          } else {
            joineddata.push(<b>{element?.text}</b>);
          }
        } else if (element.operation === 0) {
          if (!isNegotiable) {
          } else {
            if (itemData.dataType === "Datetime") {
              const newText = <s>{element?.text.replace(/T/g, " ")}</s>;
              oldData.push(newText);
            } else if (itemData.dataType === "Date") {
              oldData.push(<s>{element?.text}</s>);
            } else {
              joineddata.push(<s>{element?.text}</s>);
            }
          }
        }
      });
      if (itemData.dataType === "Datetime" || itemData.dataType === "Date") {
        return <span className="differData">{newData}</span>;
      }
      return <span className="differData">{joineddata}</span>;
    } else {
      if (isPrefilledCondition) {
        return <span className="differData">{itemData?.value}</span>;
      } else {
        return <span className="differData"></span>;
      }
    }
  };
  const getInputType = (
    itemData: any,
    subTermData: any,
    detailsData: any,
    inputInd,
    isNegotiable
  ) => {
    switch (itemData.dataType) {
      case "Text":
        return (
          <>
            {differData(itemData, isNegotiable, isPrefilledCondition)}
            {/* <input
            key={itemData.standardTermDetailId}
            type="text"
            value={itemData.value}
            className="inputText"
            onChange={(e) => handleChangeFunc(e.target.value, itemData)}
          /> */}
          </>
        );
      case "TextArea":
        return (
          <>{differData(itemData, isNegotiable, isPrefilledCondition)}</>
          // <textarea
          //   key={itemData.standardTermDetailId}
          //   value={itemData.value}
          //   className="inputTextArea"
          //   onChange={(e) => handleChangeFunc(e.target.value, itemData)}
          // />
        );
      case "SelectedCheckbox":
        return (
          <McRadio
            data-testid="input-selectedcheckbox"
            label={itemData.suffix}
            checked={itemData.value}
            change={(val) => handleChangeRadio(val, itemData)}
            name="suffix"
          ></McRadio>
        );
      case "Number":
        return (
          <>{differData(itemData, isNegotiable, isPrefilledCondition)}</>
          // <input
          //   key={itemData.standardTermDetailId}
          //   type="number"
          //   value={itemData.value}
          //   className="inputText"
          //   onChange={(e) => handleChangeFunc(e.target.value, itemData)}
          // />
        );
      case "Date":
        return (
          <>{differData(itemData, isNegotiable, isPrefilledCondition)}</>
          // <DatePicker
          //   className="inputText"
          //   dateFormat="dd/MM/yyyy"
          //   selected={itemData.value == null ? "" : new Date(itemData.value)}
          //   onChange={(date: any) => {
          //     handleChangeFunc(date, itemData);
          //   }}
          //   key={itemData.standardTermDetailId}
          // ></DatePicker>
        );

      case "Datetime":
        return (
          <div className="DateTimeChanges">
            {differData(itemData, isNegotiable, isPrefilledCondition)}
            {/* <DateTimePicker
            format="dd/MM/yyyy HH:mm"
            locale="en-US"
            className={"dateTimeClass"}
            onChange={(e) => handleChangeFunc(moment(e).format("DD/MM/YYYYTHH:mm"), itemData)}
            value={convertDateFormat(itemData.value)}
            key={itemData.standardTermDetailId}
          /> */}
            <span className="sufText">{itemData.suffix}</span>
          </div>
        );
      case "Dropdown":
        return (
          <>{differData(itemData, isNegotiable, isPrefilledCondition)}</>
          // <Select
          //   defaultValue={
          //     itemData.value === "Yes"
          //       ? { value: "Yes", label: "Yes" }
          //       : { value: "No", label: "No" }
          //   }
          //   options={[
          //     { value: "Yes", label: "Yes" },
          //     { value: "No", label: "No" },
          //   ]}
          //   key={itemData.id}
          //   onChange={(value) => handleSelectOnchange(value, itemData)}
          // />
        );
      case "PrefixDropdown":
        return (
          <div className="prefixText">
            <span className="preText">{itemData.prefix}</span>
            {differData(itemData, isNegotiable, isPrefilledCondition)}
            {/* <input
              data-testid="prefixdropdown"
              key={itemData.standardTermDetailId}
              type="text"
              value={itemData.value}
              className="inputText"
              onChange={(e) => handleChangeFunc(e.target.value, itemData)}
            /> */}
            {/* <Select
              defaultValue={getDefaultDropdownvalue(
                itemData.options,
                itemData.value
              )}
              className="inputDropDown"
              options={itemData.options}
              key={itemData.id}
              onChange={(value) => handleSelectOnchange(value, itemData)}
            /> */}
            <span className="sufText">{itemData.suffix}</span>
          </div>
        );
      case "PrefixText":
        return (
          <div className="prefixText">
            <span className="preText">{itemData.prefix}</span>
            {differData(itemData, isNegotiable, isPrefilledCondition)}
            {/* <input
              key={itemData.standardTermDetailId}
              type="text"
              value={itemData.value}
              className="inputText"
              onChange={(e) => handleChangeFunc(e.target.value, itemData)}
            /> */}
            <span className="sufText">{itemData.suffix}</span>
          </div>
        );
      case "PrefixNumber":
        return (
          <div className="prefixText">
            <span className="preText">{itemData.prefix}</span>
            {differData(itemData, isNegotiable, isPrefilledCondition)}
            {/* <input
              key={itemData.standardTermDetailId}
              type="number"
              value={itemData.value}
              className="inputText"
              onChange={(e) => handleChangeFunc(e.target.value, itemData)}
            /> */}
            <span className="sufText">{itemData.suffix}</span>
          </div>
        );
      case "Dropdown&Input":
        return (
          <>
            {/* {differData(itemData , isNegotiable)} */}
            <Select
              data-testid="dropdowninput"
              defaultValue={
                itemData.value !== "No"
                  ? { value: "Yes", label: "Yes" }
                  : { value: "No", label: "No" }
              }
              options={[
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ]}
              key={itemData.standardTermDetailId}
              onChange={(value) => handleSelectOnchange(value, itemData)}
            />
            <div className="input-dropdown">
              <label> Comment</label>
              <input
                key={itemData.standardTermDetailId}
                type="text"
                value={itemData.value2}
                className="inputText"
              />
            </div>
          </>
        );
      case "Checkbox":
        return (
          <>
            <McCheckbox
              key={itemData.standardTermDetailId}
              name="disclaimer"
              checked={itemData.value === "true" ? true : false}
              // change={(val) =>
              //   handleChangeFunc(
              //     String((val.target as HTMLInputElement).checked),
              //     itemData
              //   )
              // }
              label="Please select the Checkbox if the below field is required"
            ></McCheckbox>
          </>
        );
      default:
        return differData(itemData, isNegotiable, isPrefilledCondition);
      // <input
      //   key={itemData.standardTermDetailId}
      //   type="text"
      //   value={itemData.value}
      //   className="inputText"
      //   onChange={(e) => handleChangeFunc(e.target.value, itemData)}
      // />
    }
  };
  useEffect(() => {
    setmainData(subTermData);
  }, [subTermData]);
  return (
    <div className="sub-data" key={mainData?.subTermId}>
      {isPrefilledCondition && mainData?.subTermName == "Delivery notices" ? (
        ""
      ) : (
        <div className="sub-header">
          <span className="icon">
            <McIcon icon="vessel-front" />
          </span>
          <span className="action-btn"></span>
          <span className="text">
            {" "}
            {isPrefilledCondition && mainData?.subTermName == "Laycan"
              ? "New Time and Period"
              : mainData?.subTermName}
          </span>
        </div>
      )}
      {isPrefilledCondition && mainData?.subTermName == "Delivery notices"
        ? ""
        : mainData?.details?.map((detailsData: any, ind: any) => (
            <div>
              {isPrefilledCondition && detailsData?.label == "LayCan To" ? (
                ""
              ) : (
                <div
                  className="each-data"
                  key={detailsData?.standardTermDetailId}
                >
                  <span className="label-div">
                    {mainData?.subTermName !== detailsData.label
                      ? isPrefilledCondition &&
                        detailsData.label == "LayCan From"
                        ? "From"
                        : mainData?.newTerm ? "" : detailsData.label
                      : ""}
                    {/* {detailsData.lineType !== "Description" &&
                detailsData.revertLater && (
                  <span className="checkBox isDisbaled">
                    <McCheckbox
                      fit="small"
                      label="Revert Next Counter"
                      value={detailsData.revertLater}
                      checked={detailsData.revertLater}
                      key={detailsData.standardTermDetailId}
                    ></McCheckbox>
                  </span>
                )} */}
                  </span>

                  {detailsData?.inputs?.map((inputData: any, inputInd: any) => (
                    <>
                      {" "}
                      <span className="content-div">
                        {detailsData?.labelDescription}
                      </span>
                      <div
                        className="input-section"
                        data-testid="input-type"
                        key={inputData?.standardTermDetailId}
                      >
                        {inputData?.lineType !== "Description" &&
                          getInputType(
                            inputData,
                            mainData,
                            detailsData,
                            inputInd,
                            mainData.isNegotiable
                          )}
                        {inputData?.lineType !== "Description" && (
                          <StandardClauseHistory
                            itemdata={inputData}
                            indicationId={indicationId}
                          />
                        )}
                      </div>
                    </>
                  ))}
                </div>
              )}
            </div>
          ))}
    </div>
  );
};
export default InputSection;