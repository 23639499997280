import { useEffect, useState } from "react";
import { McModal, McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
interface IPROPS {
    isModalOpen?: boolean;
    isCloseModal?: any;
    infoData?: any;
    setEditedVORDetails?: any;
    crId?: any;
    deletedRepresentative?: any;
    userRole?:any;
    isReadOnlyUser?:any;
    requestWriteAccessModal?: any;
    requestWriteAccessSuccess?:any;
    rowAccessType?:any;
}
const EditVORDetailsModal = ({
    isModalOpen,
    isCloseModal,
    infoData,
    setEditedVORDetails,
    crId,
    deletedRepresentative,
    userRole,
    isReadOnlyUser,
    requestWriteAccessModal,
    requestWriteAccessSuccess,
    rowAccessType
}: IPROPS) => {
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm();
    const [submitError, setSubmitError] = useState(false);
    const [representatives, setRepresentatives] = useState(
        infoData?.vOR_AssignmentUserDtos
    );
    // const [representatives, setRepresentatives] = useState(
    //   infoData?.vOR_AssignmentUserDtos : infoData?.cmrAssignmentUserDetails || []
    // );
    const commentValue = infoData?.comment;
    const accessType = [
        { value: "Write", label: "Write" },
        { value: "Read", label: "Read" },
    ];
    const accessType1 = [{ value: "Read", label: "Read" }];
    const onSubmit = (data) => {
        const accessTypes = representatives?.map(
            (_, index) => data[`accessType${index}`]
        );
        let result = representatives?.map((rep, index) => ({
            name: data[`name${index}`],
            email: data[`email${index}`],
        }));
        
        const formattedData = {
            id: infoData?.id,
            indicationId: Number(crId),
            comment: data.comments,
            vOR_AssignmentUserDtos: representatives?.map((rep, index) => ({
                vorAssignmentUserId: rep?.vorAssignmentUserId
                    ? rep?.vorAssignmentUserId
                    : 0,
                name: data[`name${index}`],
                email: data[`email${index}`],
                accessType: data[`accessType${index}`] ? data[`accessType${index}`]:rep[`accessType`],
            })),
        };
        setSubmitError(false);
        if (formattedData.vOR_AssignmentUserDtos.filter((dto) => dto.accessType === "Write").length > 1) {
            setSubmitError(true);
            return;
        }
  
        setEditedVORDetails(formattedData);
        isCloseModal();
    };
    const onError = (errors, e) => console.log(errors, e);
    const chkValueChanged = (e: any, label: string) => { };
    const customStyles = {
        control: (base: any) => ({
            ...base,
            height: 48,
            minHeight: 38,
        }),
    };
    const onDeleteVORDetails = (index) => {
        const deletedRep = representatives.filter((_, i) => i == index);
        const updatedRepresentatives = representatives.filter(
            (_, i) => i !== index
        );
        setRepresentatives(updatedRepresentatives);
        deletedRepresentative(deletedRep[0]["vorAssignmentUserId"]);
        isCloseModal();
    };
    const addMoreFunc = () => {
        if (representatives.length >= 5) {
            return;
        }
        setRepresentatives([
            ...representatives,
            {
                id: representatives.length + 1,
                name: "",
                email: "",
                accessType: "Read",
            },
        ]);
    };

    const requestWriteAcess = () => {
        requestWriteAccessModal(true);
      }
 
    return (
        <>
            <form
                data-testid="form"
                className="charterMarketAddModal"
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <McModal
                    height="500px"
                    width="800px"
                    className="broker-info-modal"
                    heading={`Edit Representative`}
                    open={isModalOpen}
                    backdropcloseactiondisabled
                >
                    <div>
                        {submitError && (
                            <span
                                style={{
                                    color: "red",
                                    marginLeft: "10px",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                }}
                            >
                                * Only one "Write" access is allowed
                            </span>
                        )}
                    </div>
                    {/* <button>Request Write Access</button> */}
                    <div
                        className="closeIcon-modal"
                        data-testid="close"
                        onClick={isCloseModal}
                    >
                        <McIcon icon="times"></McIcon>
                    </div>
                    {(!requestWriteAccessSuccess && userRole === "VesselOwner") && (<div className="request-write-access-btn">
            <McButton onClick={requestWriteAcess} variant="secondary" type="button" fit="medium">Request Write Access</McButton>
          </div>)}
          <br/>

          {(requestWriteAccessSuccess && userRole === "VesselOwner") && (<div className="request-write-access-btn">
            <McButton onClick={requestWriteAcess} disabled variant="secondary" type="button" fit="medium">Request Write Access</McButton>
          </div>)}
                   
                    <div className="broker-data">
                        <div className="broker-data-io details-section">
                            {representatives?.map((data: any, index: any) => {
                                return (
                                    <div key={index} className="row details-row">
                                        <div className="col-md-4">
                                            <div className="header space-btw">Name</div>
                                            <div className="value">
                                                <Controller
                                                    name={`name${index}`}
                                                    control={control}
                                                    data-testid="name"
                                                    render={({ field: { onChange } }) => (
                                                        <input
                                                            className="inputText"
                                                            {...register(`name${index}`)}
                                                            type="text"
                                                            role="name"
                                                            onChange={(val) => {
                                                                chkValueChanged(val, `name${index}`);
                                                                onChange(val.target.value);
                                                            }}
                                                            defaultValue={data?.name}
                                                            readOnly={
                                                                (isReadOnlyUser || rowAccessType === "Read") ? true : false
                                                              }
                                                        ></input>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="header">E-mail</div>
                                            <div className="value">
                                                <Controller
                                                    name={`email${index}`}
                                                    control={control}
                                                    data-testid="email"
                                                    render={({ field: { onChange } }) => (
                                                        <input
                                                            className="inputText"
                                                            {...register(`email${index}`)}
                                                            type="text"
                                                            role="email"
                                                            onChange={(val) => {
                                                                chkValueChanged(val, `email${index}`);
                                                                onChange(val.target.value);
                                                            }}
                                                            defaultValue={data?.email}
                                                            readOnly={
                                                                (isReadOnlyUser || rowAccessType === "Read") ? true : false
                                                              }
                                                        ></input>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="header">Access type</div>
                                            <div className="value">
                                                <Controller
                                                    control={control}
                                                    name={`accessType${index}`}
                                                    data-testid="accessType"
                                                    render={({ field: { onChange } }) => (
                                                        <Select
                                                            styles={customStyles}
                                                            defaultValue={{
                                                                value: data?.["accessType"],
                                                                label: data?.["accessType"],
                                                            }}
                                                            options={accessType}
                                                            name={`accessType${index}`}
                                                            onChange={(val: any) => onChange(val.value)}
                                                            isDisabled={
                                                                (isReadOnlyUser || rowAccessType === "Read") ? true : false
                                                            }
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                         <div className="col-md-1">
                                            <div className="header"></div>
                                            {(!isReadOnlyUser && rowAccessType === "Write")  && <div className="value thrash-icon">
                                                <McIcon
                                                    data-testid="delete-option"
                                                    icon="trash"
                                                    onClick={() => onDeleteVORDetails(index)}
                                                ></McIcon>
                                            </div>}
                                        </div> 
                                    </div>
                                );
                            })}
                        </div>
                        {(!isReadOnlyUser && rowAccessType === "Write") && <div className="broker-data-io details-section">
                            <div className="row">
                            <div className="col-md-4">
                                    {representatives?.length < 5 && (
                                        <span className="add-more" onClick={addMoreFunc}>
                                            + Add more
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>}
                        <div className="broker-data-io details-section">
                            <div className="col-md-11 comments-section">
                                <label>Comments</label>
                                <textarea
                                    data-testid="cm-comments"
                                    className="commentArea"
                                    {...register("comments")}
                                    defaultValue={commentValue !== null ? commentValue : ""}
                                    placeholder="comments"
                                    rows={3}
                                    readOnly={isReadOnlyUser || rowAccessType === "Read"}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <McButton
                        slot="secondaryAction"
                        variant="secondary"
                        dialogAction="cancel"
                        onClick={isCloseModal}
                        role="cancel-cp"
                        fit="small"
                    >
                        Close
                    </McButton>
                    {(!isReadOnlyUser && rowAccessType === "Write") && <McButton
                        slot="primaryAction"
                        variant="primary"
                        type="submit"
                        label="Confirm"
                        data-testid="confirm"
                        fit="small"
                    ></McButton>}
                </McModal>
            </form>
        </>
    );
};
export default EditVORDetailsModal;
