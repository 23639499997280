import React, { useEffect } from "react";
import { useState } from "react";
import {
  McModal,
  McButton,
  McSelect,
  McIcon,
  McCheckbox,
  McLoadingIndicator,
} from "@maersk-global/mds-react-wrapper";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import { getAPI, postAPI } from "../../services/ApiServices";
import { toast } from "react-toastify";
import "./UploadModal.scss";
import { DataRow } from "./model";
import moment from "moment";

interface IPROPS {
  onOpen?: boolean;
  closeValid?: any;
  pageData?: any;
  uploadFile?: any;
}

const OnholdModal = ({ onOpen, closeValid, pageData, uploadFile }: IPROPS) => {
  const [upadteData, setUpdateData] = useState([]);
  const [isUpdateButton, setIsUpdateButton] = useState(false);
  const getPageData = async () => {
    const resp = await getAPI("Charter/GetMismatchedCharter");
    setUpdateData(resp?.mismatchedCharterUserActions);
  };
  useEffect(() => {
    getPageData();
  }, [onOpen]);

  const onCancel = () => {
    closeValid();
  };
  const rowSelectCritera = (row: any) => {
    const val: any = upadteData?.map((item: any) => {
      if (item.imo === row.target.name) {
        if (row.target.label === "Retain") {
          item.old[0].userAction = row.target.checked;
          item.userAction = false;
        } else {
          item.userAction = row.target.checked;
          item.old[0].userAction = false;
        }
      }
      return item;
    });
    setUpdateData(val);
  };

  const onSubmitData = async () => {
    setIsUpdateButton(true);
    const resp = await postAPI(
      `Charter/UpdateRetainPendingMismatchedRecordsAsync`,
      upadteData
    );
    closeValid();
    setIsUpdateButton(false);
    resp?.forEach((item: any) => {
      if (item.failedRecordCount >= 1) {
        if (item.message == "Updated Records") {
          toast.success(
            <div className="toast-success">
              <p className="header"> Record uploaded Successfully!</p>
              <p className="msg">
                <span>{item.failedRecordCount}</span> New commercial details
                replaced to the existing records.
              </p>
            </div>,
            {
              theme: "colored",
              icon: false,
              autoClose: 3000,
            }
          );
        } else if (item.message == "Invalid Records") {
          toast.error(
            <div className="toast-success">
              <p className="header"> Unsuccessful!</p>
              <p className="msg">
                <span>Upload Failed</span> for {item.failedRecordCount} invalid
                records
              </p>
            </div>,
            {
              theme: "colored",
              icon: false,
              autoClose: 4000,
            }
          );
        } else {
          toast.warn(
            <div className="toast-success">
              <p className="header"> Old data retained Successfully!!</p>
              <p className="msg">
                <span>{item.failedRecordCount}</span> Old commercial details
                retained to the list.
              </p>
            </div>,
            {
              theme: "colored",
              icon: false,
              autoClose: 4000,
            }
          );
        }
      }
    });
  };
  const columns: TableColumn<DataRow>[] = [
    {
      id:"vessel-name",
      name: "Vessel Name",
      selector: (row) => row.vesselName,
    },
    {
      id:"vessel-name-critera",
      name: "",
      selector: (row) => row.vesselName,
      grow: 2,
      cell: (row) => (
        <McCheckbox
          data-testid='check-box'
          onchange={rowSelectCritera}
          name={row.imo}
          label="New"
          checked={row.userAction}
          value={row.userAction}
        ></McCheckbox>
      ),
      reorder: true,
    },
    {
      id:"source",
      name: "Source",
      selector: (row) => row.source,
    },
    {
      id:"last-updated-date",
      name: "Last Updated Date",
      grow: 3,
      selector: (row:any) =>
        row.lastUpdatedDate != null
          ? moment(row.lastUpdatedDate).format("DD-MMM-YYYY")
          : row.lastUpdatedDate,
    },
    {
      id:"charterer",
      name: "Charterer",
      selector: (row) => row.chartererName,
      cell: (row) => (
        <div
          className={`${
            row.chartererName != row.old[0].chartererName && "errordata"
          }`}
        >
          {row.chartererName}
        </div>
      ),
    },
    {
      id:"currency",
      name: "Currency",
      selector: (row) => row.currencyName,
      cell: (row) => (
        <div
          className={`${
            row.currencyName != row.old[0].currencyName && "errordata"
          }`}
        >
          {row.currencyName}
        </div>
      ),
    },
    {
      id:"rate",
      name: "Rate",
      selector: (row) => row.rate,
      cell: (row) => (
        <div className={`${row.rate != row.old[0].rate && "errordata"}`}>
          {row.rate}
        </div>
      ),
    },
    {
      id:"delivery",
      name: "Delivery",
      selector: (row) => row.delivery,
      cell: (row) => (
        <div
          className={`${row.delivery != row.old[0].delivery && "errordata"}`}
        >
          {row.delivery != null
            ? moment(row.delivery).format("DD-MMM-YYYY")
            : row.delivery}
        </div>
      ),
    },
    {
      id:"min",
      name: "Min",
      selector: (row) => row.min,
      cell: (row) => (
        <div className={`${row.min != row.old[0].min && "errordata"}`}>
          {row.min != null ? moment(row.min).format("DD-MMM-YYYY") : row.min}
        </div>
      ),
    },
    {
      id:"max",
      name: "Max",
      selector: (row) => row.max,
      cell: (row) => (
        <div className={`${row.max != row.old[0].max && "errordata"}`}>
          {row.max != null ? moment(row.max).format("DD-MMM-YYYY") : row.max}
        </div>
      ),
    },
    {
      id:"type",
      name: "Type",
      selector: (row) => row.charterTypeName,
      cell: (row) => (
        <div
          className={`${
            row.charterTypeName != row.old[0].charterTypeName && "errordata"
          }`}
        >
          {row.charterTypeName}
        </div>
      ),
    },
  ];
  const subcolumns: TableColumn<DataRow>[] = [
    {
      name: "Vessel Name",
      selector: (row) => row.vesselName,
    },
    {
      name: "",
      selector: (row) => row.vesselName,
      grow: 2,
      cell: (row) => (
        <McCheckbox
          data-testid="retain-checkbox"
          onchange={rowSelectCritera}
          name={row.imo}
          label="Retain"
          checked={row.userAction}
          value={row.userAction}
        ></McCheckbox>
      ),
      reorder: true,
    },
    {
      name: "Source",
      selector: (row) => row.source,
    },
    {
      name: "Last Updated Date",
      grow: 3,
      selector: (row:any) =>
        row.lastUpdatedDate != null
          ? moment(row.lastUpdatedDate).format("DD-MMM-YYYY")
          : row.lastUpdatedDate,
    },
    {
      name: "Charterer",
      selector: (row) => row.chartererName,
    },
    {
      name: "Currency",
      selector: (row) => row.currencyName,
    },
    {
      name: "Rate",
      selector: (row) => row.rate,
    },
    {
      name: "Delivery",
      selector: (row) =>
        row.delivery != null
          ? moment(row.delivery).format("DD-MMM-YYYY")
          : row.delivery,
    },
    {
      name: "Min",
      selector: (row) =>
        row.min != null ? moment(row.min).format("DD-MMM-YYYY") : row.min,
    },
    {
      name: "Max",
      selector: (row) =>
        row.max != null ? moment(row.max).format("DD-MMM-YYYY") : row.max,
    },
    {
      name: "Type",
      selector: (row) => row.charterTypeName,
    },
  ];
  const ExpandedComponent: React.FC<ExpanderComponentProps<DataRow>> = ({
    data,
  }) => {
    return (
      <>
        <DataTable
          className="onHoldDataTable"
          columns={subcolumns}
          data={data?.old}
        />
      </>
    );
  };
  return (
    <McModal
      data-testid="onhold-modal"
      open={onOpen}
      heading={`${
        uploadFile != "Broker Availability List" ? "MisMatched Records" : ""
      }`}
      width="70vw"
      backdropcloseactiondisabled
      height="60vh"
    >
      <div className="modalValidation-section">
        <div className="subColoum">
          <div className="closeIcon" data-testid="cancel" onClick={() => onCancel()}>
            <McIcon icon="times"></McIcon>
          </div>
          <div className="header-section"></div>

          <div className="table-section">
            <DataTable
              columns={columns}
              data={upadteData}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              expandableRowExpanded={(row) => true}
              data-testid="updated-page-data"
              // onColumnOrderChange={(cols) => console.log("c", cols)}
            />
          </div>
        </div>
      </div>
      {upadteData?.length > 0 && (
        <>
          <McButton
            slot="primaryAction"
            onClick={onSubmitData}
            variant="primary"
            disabled={isUpdateButton}
            data-testid="update-button"
          >
            Update
          </McButton>
          <McButton
            data-testid="cancel"
            slot="secondaryAction"
            variant="secondary"
            onClick={onCancel}
            dialogAction="cancel"
          >
            Cancel
          </McButton>
        </>
      )}
    </McModal>
  );
};

export default OnholdModal;
