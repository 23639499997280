import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import { postAPI } from "../../../services/CepApiServices";

import "./IndicationRequestTable.scss";
import { SkeletonLoader } from "../../../components/skeletonLoader/SkeletonLoader";
import { MdsConstants } from "../../../utils/Constants";
import { IndicationRequestModel } from "./model";
import ConfirmationModal from "../../../CharterRequest/RequestPages/IndicationInvite/ConfirmationModal";
import { AppString } from "../../../utils/AppString";
import { getAPI } from "../../../services/CepApiServices";
import { toast } from "react-toastify";
import { McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import WriteAccessModal from "./WriteAccessModal";
import { StatusList } from "../../../utils/StatusList";
interface IPROPS {
  pageData?: object;
}
const IndicationRequestTable = ({ pageData }: IPROPS) => {
  const history = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [dDlOptions, setTableSizeDdl] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [pageLoad, setPageLoad] = useState(false);
  const [isWriteAccess, SetIsWriteAccess] = useState(false);
  const [rowData, setRowData] = useState<any>();
  let numOfRecords = 0;

  const onLoadDataFetch = async (payload: any) => {
    setIsTableLoader(true);
    setTableSizeDdl(MdsConstants.ItemsPerPageDDl);
    const finalPayload = {
      RequestId: payload?.RequestId?.length == 0 ? null : payload?.RequestId,
      StatusList: payload?.StatusList?.length == 0 ? null : payload?.StatusList,
    };
    const getIndicationRequest = await postAPI(
      `IndicationRequest/GetAllIndicationRequest`,
      finalPayload
    );
    setTableData(getIndicationRequest);
    setIsTableLoader(false);
    numOfRecords = getIndicationRequest ? getIndicationRequest?.length : 0;
    setTotalPages(numOfRecords / 10);
  };
  const pageReload = () => [onLoadDataFetch(pageData)];
  const handelCancelFun = (row: any) => {
    let res =
      row.indicationStatus === "Indication Sent" && row.accessType == "Write";
    if (res) {
      return (
        <span
          className="cancel-indication"
          onClick={() => {
            cancelPopUp(row.id);
          }}
        >
          Cancel
        </span>
      );
    } else {
      return <span>--</span>;
    }
  };
  const handleWriteAssigneeFun = (value: any) => {
    SetIsWriteAccess(true);
    setRowData(value);
  };
  const handleAssignee = (row: any) => {
    if (
      row?.statusId !== 1 &&
      row?.statusId !== 3 &&
      row?.statusId !== 7 &&
      row?.statusId !== 16 && //Terminated requests
      row.accessType === "Write"
    ) {
      return (
        <span
          className="manage-access"
          onClick={() => handleWriteAssigneeFun(row)}
        >
          Manage Access
        </span>
      );
    } else {
      return "--";
    }
  };
  const closeFunc = () => {
    SetIsWriteAccess(false);
  };
  const cancelPopUp = (idValue: any) => {
    setModalOpen(true);
    setId(idValue);
  };
  const SaveOrCancelReject = async (response: string) => {
    if (response == "Yes") {
      setModalOpen(false);
      let res = await getAPI(
        `IndicationRequest/UpdateIndicationRequestStatus` +
          `?indicationId=${id}` +
          `&action=${"cancel"}`
      );
      if (res) {
        toast.success(<div> Indication Request Canceled</div>, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
      } else {
        toast.error(<div> Indication Request Cancelation Failed</div>, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
      }
      setPageLoad(true);
    } else {
      //Only close the modal
      setModalOpen(false);
    }
  };
  
  const accessTypeSetter = (accessType: any) => {
    if (accessType == "Read") {
      return (
        <span className="read-access">
          <McIcon icon="eye" className="read-access-icon"></McIcon>
          {accessType}
        </span>
      );
    } else if (accessType == "Write") {
      return (
        <span className="write-access">
          <McIcon icon="pencil" className="write-access-icon"></McIcon>
          {accessType}
        </span>
      );
    }
  };
  const handleLongText = (str: any) => {
    return str?.split(",").map((subStr: any) => {
      return (
        <div className="long-text">
          {subStr}
          <br />
        </div>
      );
    });
  };
  const columns: TableColumn<IndicationRequestModel>[] = [
    {
      name: "Status",
      selector: (row) => row?.indicationStatus,
      cell: (row) => StatusList(row?.statusId, row?.indicationStatus, ""),
      sortable: true,
      width: "9%",
    },
    {
      name: "Request Name",
      selector: (row) =>
        row?.requestName !== null ? row?.requestName.toLowerCase() : "",
      cell: (row) => (row?.requestName !== null ? row?.requestName : ""),
      sortable: true,
      width: "9%",
    },
    {
      name: "Vessel Name",
      selector: (row) =>
        row?.vesselName !== null ? row?.vesselName.toLowerCase() : "",
      cell: (row) => (row?.vesselName !== null ? row?.vesselName : ""),
      sortable: true,
      width: "8.5%",
    },
    {
      name: "IMO",
      selector: (row) => (row?.imo !== null ? row?.imo : ""),
      sortable: true,
      width: "5%",
    },
    {
      name: "Chartering Manager",
      selector: (row) =>
        row?.charteringManagerName !== null ? row?.charteringManagerName : "",
      sortable: true,
      width: "15%",
    },
    {
      name: "CM Representative",
      selector: (row) =>
        row?.charteringManagerRepresentativeName !== null ? row?.charteringManagerRepresentativeName : "",
      cell: (row) => (row?.charteringManagerRepresentativeName !== null ? row?.charteringManagerRepresentativeName : ""),
      sortable: true,
      width: "15%",
    },
    {
      name: "Vessel Owner",
      selector: (row) =>
        handleLongText(
          row?.vesselOwnerName !== null ? row?.vesselOwnerName : ""
        ),
      sortable: true,
      width: "14%",
    },
    {
      name: "VO Representative",
      selector: (row) =>
        handleLongText(
          row?.vesselOwnerRepresentativeName !== null ? row?.vesselOwnerRepresentativeName : ""
        ),
      sortable: true,
      width: "14%",
    },
    {
      name: "Action",
      cell: (row) => handelCancelFun(row),
      width: "6%",
    },
  ];
  const tableRowClick = (row: any) => {
    if (row.isDirectNegotiation !== "No") return false;
    let checkAccpetdRequest = tableData.some(function (item) {
      return (
        (item["requestName"] === row.requestName &&
          item["statusId"] >= 8 &&
          item["statusId"] <= 15) ||
        (item["requestName"] === row.requestName && item["statusId"] == 6) ||
        (item["requestName"] === row.requestName && item["statusId"] == 17)
      );
    });
    history(`/GetIndicationRequestDataAsync?id=${row.id}`);
  };

  useEffect(() => {
    onLoadDataFetch(pageData);
  }, [pageData, pageLoad]);

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  const cursorPointerStyle = { cursor: "pointer" };
  const cursorDefaultStyle = { cursor: "default" };
  const conditionalRowStyles = [
    {
      when: (row) => row.isDirectNegotiation === "No",
      style: cursorPointerStyle,
    },
    {
      when: (row) => row.isDirectNegotiation !== "No",
      style: cursorDefaultStyle,
    },
  ];
  return (
    <div className="ir-table-main table-margin2 bg-white">
      <DataTable
        columns={columns}
        data={tableData}
        progressPending={isTableLoader}
        onRowClicked={tableRowClick}
        progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        conditionalRowStyles={conditionalRowStyles}
      />
      <ConfirmationModal
        onModalOpen={modalOpen}
        onModalClose={SaveOrCancelReject}
        modalTxt={AppString.cancelModalTxt}
        width={"auto"}
        height={"130px"}
      />
      <WriteAccessModal
        isModalOpen={isWriteAccess}
        onCloseFunc={closeFunc}
        rowData={rowData}
        isCm={true}
        reloadPage={pageReload}
      />
    </div>
  );
};
export default IndicationRequestTable;
