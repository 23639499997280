// React
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IconContext } from "react-icons";
import { getUserRole, getUserName } from "../../utils/token";
// Styles
import "./SideMenu.scss";

// Components
import SideBarData from "../sideBarData/SideBarData";
import { McIcon } from "@maersk-global/mds-react-wrapper";
import { protectedResources } from "../../authConfig";
import { Sidetab } from "@maersk-global/insightshub-embed-react";
import {
  SUS_FORM_ID,
  SUS_FORM_ID_SIT,
  SUS_FORM_ID_PROD,
} from "../../utils/Constants";
interface IPROPS {
  onSideClick?: any;
  accessDenied?: boolean;
}
export const SideMenu = ({ onSideClick, accessDenied }: IPROPS) => {
  const [filteredSideBarData, setFilteredSideBarData] = useState<any>([]);
  const [showFeedBackPopup, setShowFeedBackPopup] = useState(true);
  console.log("%%%" + process.env.REACT_APP_ENVIRONMENT);
  const [sidebar, setSidebar] = useState(false);
  const location = useLocation();
  const [emailId, setEmailId] = useState("");
  const [susID, setSusID] = useState("");
  const showSidebar = () => {
    setSidebar(!sidebar);
    onSideClick(sidebar);
  };
  const checkSubNavMenu = (item: any) => {
    if (item.path == location.pathname) {
      return true;
    } else if (
      item?.subNav &&
      item.subNav?.map((i: any) => i?.path == location.pathname)?.[0]
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getAptMenus = async () => {
    const userName = await getUserName();
    setEmailId(userName?.email);
    if (process.env.REACT_APP_ENVIRONMENT == "Dev") {
      setSusID(SUS_FORM_ID);
    } else if (process.env.REACT_APP_ENVIRONMENT == "Sit") {
      setSusID(SUS_FORM_ID_SIT);
    } else if (process.env.REACT_APP_ENVIRONMENT == "prod") {
      setSusID(SUS_FORM_ID_PROD);
    }
    if (window.location.href.startsWith("https://al.")) {
      setShowFeedBackPopup(false);
      const updatedSideBarData = SideBarData.filter((item) => {
        if (item.page === "vessel") return item;
      });
      setFilteredSideBarData(updatedSideBarData);
    } else if (window.location.href.startsWith("https://cep.")) {
      if (accessDenied === true) {
        setFilteredSideBarData([]);
      } else {
        const userRole = await getUserRole();
        const updatedSideBarData = SideBarData.filter((item) => {
          if (item.accessTo && item.accessTo.includes(userRole)) return item;
        });
        setFilteredSideBarData(updatedSideBarData);
      }
    } else if (window.location.href.startsWith("http://localhost")) {
      if (protectedResources.apiTodoList.isAzure) {
        const updatedSideBarData = SideBarData.filter((item) => {
          if (item.page === "vessel") return item;
        });
        setFilteredSideBarData(updatedSideBarData);
      } else {
        if (accessDenied === true) {
          setFilteredSideBarData([]);
        } else {
          const userRole = await getUserRole();
          const updatedSideBarData = SideBarData.filter((item) => {
            if (item.accessTo && item.accessTo.includes(userRole)) return item;
          });
          setFilteredSideBarData(updatedSideBarData);
        }
      }
    }
  };

  useEffect(() => {
    getAptMenus();
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items">
            <span
              className="arrowclick"
              data-testid="showSidebar"
              onClick={showSidebar}
            >
              {
                <McIcon
                  icon={
                    sidebar ? "double-chevron-left" : "double-chevron-right"
                  }
                  color="#ffffff"
                ></McIcon>
              }
            </span>
            <li className="navbar-toggle">
              <Link to="/" className="menu-bars">
                {sidebar ? (
                  <img
                    src="assets/LogoDark.png"
                    alt="maersk logo"
                    className="big-logo"
                  />
                ) : (
                  <img
                    src="assets/logo.png"
                    alt="maersk logo"
                    className="small-logo"
                  />
                )}
              </Link>
            </li>
            {filteredSideBarData.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`${item.cName} ${
                    checkSubNavMenu(item) && "active"
                  }`}
                >
                  <Link
                    to={item.path}
                    className={!sidebar ? "bottom-title" : ""}
                  >
                    <span className={!sidebar ? "icon-view" : ""}>
                      {" "}
                      <McIcon
                        icon={item.icon}
                        color={checkSubNavMenu(item) ? "#42b0d5" : "#ffffff"}
                      ></McIcon>
                    </span>
                    <span
                      className={!sidebar ? "tittle-hide" : ""}
                      data-testid={item.id}
                    >
                      {item.title}
                    </span>
                  </Link>
                </li>
              );
              // }
            })}
          </ul>
          {showFeedBackPopup && (
            <Sidetab id={susID} buttonText="Open Feedback" email={emailId} />
          )}
        </nav>
      </IconContext.Provider>
    </>
  );
};
