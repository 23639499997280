import { McModal, McButton } from "@maersk-global/mds-react-wrapper";
import { useEffect, useState } from "react";
import { postAPI } from "../../../../services/CepApiServices";
import { toast } from "react-toastify";
interface IPROPS {
  isModalOpen?: boolean;
  payLoadData?: any;
  onCloseFunc?: any;
  subTermData?: any;
  indicationId?: any;
  submitType?: any;
  reRenderApiFunc?: any;
  checkWriteAccess?: any;
}
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
const OfferSubmitModal = ({
  isModalOpen,
  payLoadData,
  onCloseFunc,
  subTermData,
  indicationId,
  submitType,
  reRenderApiFunc,
  checkWriteAccess,
}: IPROPS) => {
  const [revertData, setRevertData] = useState<any>([]);
  const [isRevertNextCounter, setIsRevertNextCounter] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async () => {
    setIsSubmitting(true);
    const isAcknowledged: any = submitType === "Acknowledge" ? true : false;
    if(isAcknowledged){
      const response = await postAPI(
        `InitialOffer/StageStandardTermAcceptStatus?indicationId=${indicationId}&termId=${subTermData.subTermId}`
      );
      if (response.status) {
        toast.error(`Terms Accept failed`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
        reRenderApiFunc();
        setIsSubmitting(false);
      } else {
        toast.success(`Terms Accepted successfully`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
        reRenderApiFunc();
        setIsSubmitting(false);
      }
    } else{
      const response = await postAPI(
        `InitialOffer/SaveStandardTermChanges?indicationId=${indicationId}`,
        payLoadData
      );
      if (response.status) {
        toast.error(`Terms submission failed`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
        reRenderApiFunc();
        setIsSubmitting(false);
      } else {
        toast.success(`Terms submitted successfully`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
        reRenderApiFunc();
        setIsSubmitting(false);
      }
    }
   
  };
  const onCancelData = () => {
    onCloseFunc();
  };
  useEffect(() => {
    if (isModalOpen) {
      const newArray: any = [];
      if (submitType === "Acknowledge") {
        if(subTermData?.revertLaterCounterEnabled === true){
          setIsRevertNextCounter(true)
        }else{
        setIsRevertNextCounter(false)
        subTermData?.details.map((item: any) => {
          if (
            item.inputs.every(
              (data) => (data.value === null || data.value === "") && data.lineType !== "Description"
            )
          ) {
            newArray.push({label:item.label, type: item.lineType});
          }
        });
        setRevertData(newArray);
      }
      } else {
        subTermData?.details.map((item: any) => {
          if (!item?.revertLater) {
            if (
              item.inputs.every(
                (data) => (data.value === null || data.value === "") && data.lineType !== "Description"
              )
            ) {

              newArray.push({label:item.label, type: item.lineType});
            }
          }
        });
        setRevertData(newArray);
      }
    }
  }, [isModalOpen]);
  return (
    <McModal
      height={`${revertData.length !== 0 ? "500px" : "auto"}`}
      width="600px"
      className="offer-submission-modal"
      heading={`${
        submitType === "Acknowledge" ? "Accept" : "Submit"
      } the Terms`}
      open={isModalOpen}
      backdropcloseactiondisabled
    >
      {isRevertNextCounter ? (
        <div className="acknowledge-section">
          {`Terms cannot be accepted as the  Revert next counter is enabled.`}
        </div>
      ) : (
      <div className="acknowledge-section">
        {revertData.length !== 0
          ? `${
              submitType === "Acknowledge" ? "Do you want to Accept the term without providing the below details" : "Please provide the below details or select Revert next counter to submit this term"
            } `
          : `Do you want to ${
              submitType === "Acknowledge" ? "Accept" : "Submit"
            } the term ?`}
        <ul>
          {revertData.map((item: any) => (
            <li className="list-of-terms">{item.label}</li>
          ))}
        </ul>
      </div>
      )}
      {submitType !== "Acknowledge" &&  revertData.length === 0 && checkWriteAccess && (<McButton
        slot="primaryAction"
        variant="primary"
        fit="small"
        label={"Submit"}
        onClick={handleSubmit}
        data-testid="save-and-update"
        disabled={isSubmitting}
      ></McButton>)}
      {submitType === "Acknowledge" && checkWriteAccess && !isRevertNextCounter && (<McButton
        slot="primaryAction"
        variant="primary"
        fit="small"
        label={"Accept"}
        onClick={handleSubmit}
        disabled={isSubmitting}
        data-testid="save-and-update"
      ></McButton>)}
      <McButton
        slot="secondaryAction"
        variant="secondary"
        label="Cancel"
        onClick={onCancelData}
        role="cancel-cp"
        fit="small"
      ></McButton>
    </McModal>
  );
};
export default OfferSubmitModal;
